import React from "react";
import "./Page8.css";
import Page8a from "../assets/STAR ISLAND ESTATE PHASE 01 MASTER PLAN - MASTER PLAN 01 1.png";
// import Page8b from "../assets/Page8b.svg";

const Page8 = () => {
  return (
    <div className="container Page8main">
      {/* <h3> Proposed Site</h3> */}

      <div className="Page8Parent">
        <img src={Page8a} alt="" />
        {/* <img src={Page8b} alt="" /> */}
      </div>
    </div>
  );    
};

export default Page8;
