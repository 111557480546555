import React from "react";
import FAQImage from "../assets/Group 2794-FAQ-.png";

const Faqs = () => {
  return (
    <div className="container">
      <div className="OurStoryMain">
        <div className="storyParent">
          <h2>Frequently Asked Questions</h2>
          <p>
            Here are the answers to our most asked questions we believe would
            help you know us more.
          </p>
        </div>
      </div>

      <div className="faq-main-parent">
        <div className="faq-main-child1">
          <img src={FAQImage} alt="faq"></img>
        </div>

        <div
          class="accordion accordion-flush OurStoryChild acc"
          id="accordionFlushExample"
        >
          <div class="accordion-item">
            <h2 class="accordion-header h2" id="flush-headingOne">
              <button
                class="accordion-button collapsed accord"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Where Is Star Island?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body body">
                <p>
                  {" "}
                  Beside Lekki Phase 1, between Orange Island & Banana Island
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header h2" id="flush-headingTwo">
              <button
                class=" accordion-button collapsed accord"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Why Should I Buy from Star Island?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body body">
                <p>
                  Star Island enjoys proximity to Nigeria commercial and
                  residential nerve-hub center of Victoria Island, Ikoyi, Banana
                  Island guaranteeing hyper return on investment.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header h2" id="flush-headingThree">
              <button
                class="accordion-button collapsed accord"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Who Are The Owners Of Star Island?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body body">
                <p>It is owned by Maximum Luxury Apartments</p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header h2" id="flush-headingFour">
              <button
                class="accordion-button collapsed accord"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                What Type Of Title Does Star Island Have On The Land?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body body">
                <p> Certificate of Occupancy</p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header h2" id="flush-headingFive">
              <button
                class="accordion-button collapsed accord"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                What plot size is available
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body body">
                <p> 500sqm</p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header h2" id="flush-headingSix">
              <button
                class="accordion-button collapsed accord"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="false"
                aria-controls="flush-collapseSix"
              >
                Is the road to the estate motorable?
              </button>
            </h2>
            <div
              id="flush-collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingSix"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body body">
                <p>
                  {" "}
                  Yes, the road to the estate is paved with interlock stone and
                  well accessible from every location{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="faq-main-child">
          <img src={FAQImage} alt="faq"></img>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
