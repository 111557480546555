import React from "react";
import Form from "../components/form";

const FormPages = () => {
  return (
    <div className="container formPages">
      <h2>Contact us</h2>

      <Form />
    </div>
  );
};

export default FormPages;
