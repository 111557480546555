import view1 from "../assets/view1.png";
import view2 from "../assets/view2.png";
import view3 from "../assets/view3.png";
import view4 from "../assets/view4.png";
import view5 from "../assets/view5.png";
import view6 from "../assets/view6.png";

export const droneImage = [
  {
    id: 1,
    image: view1,
  },
  {
    id: 2,
    image: view2,
  },
  {
    id: 3,
    image: view3,
  },
  {
    id: 4,
    image: view4,
  },
  {
    id: 5,
    image: view5,
  },
  {
    id: 6,
    image: view6,
  },
];
