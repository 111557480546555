import React from "react";
import Properties3 from "../properties/Properties3";

const PendingSuite = () => {
  return (
    <div>
      <Properties3 />
    </div>
  );
};

export default PendingSuite;
