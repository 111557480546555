import icon1 from "../assets/VALUES/grommet-icons_workshop-VALUES-.svg";
import icon2 from "../assets/VALUES/icon-park-solid_local-VALUES-.svg";
import icon3 from "../assets/VALUES/material-symbols_nest-wake-on-approach-outline-VALUES-.svg";
import icon4 from "../assets/VALUES/play integrity api checker_-VALUES-.svg";

const values = [
  {
    icon: icon3,
    heading: "Client-Centric Philosophy",
    details:
      "Maximum Luxury Apartments, your goals and dreams are our top priority. We listen, understand, and tailor solutions to suit your specific requirements..",
  },
  {
    icon: icon2,
    heading: "Local Expertise, Global Reach",
    details:
      "With a firm grasp of the Nigerian market and an expansive global network, we provide you with a unique advantage in the international arena.",
  },
  {
    icon: icon4,
    heading: "Holistic Approach",
    details:
      "We offer a one-stop solution to cater to your diverse needs, be it migration, real estate investments, legal matters, or professional development.",
  },
  {
    icon: icon1,
    heading: "Integrity and Reliability",
    details:
      "Trust is at the core of our brand. You can rely on us for ethical practices, transparency, and the highest level of professionalism..",
  },
];

export default values;
