import React from "react";
import PropertiesProps from "./PropertiesProps";
import bell from "../assets/bell.png";
import PropertiesMainImage from "../../src/assets/PropertiesMainImageThree.png";
import PropertiesMainImage1 from "../../src/assets/PropertiesMainImageThreea.png";

const Properties3 = () => {
  return (
    <div>
      <PropertiesProps data={data} />
    </div>
  );
};

export default Properties3;

const data = [
  {
    id: 1,
    mainImage: PropertiesMainImage,
    image1: PropertiesMainImage1,
    image2: PropertiesMainImage1,
    image3: PropertiesMainImage1,
    image4: PropertiesMainImage1,
    propertyType: "Hectares of Land",
    value: "N100 Million",
    value2: "Hectare",
    squareMeter: "27.508 Hectares",

    bell: bell,
    body: "Hectares of Land",
    location: "Along Lekki-Epe Expressway, Lagos State",
    Areaofland: "Area of land: 27.508 Hectares",

    Title: "Title: Lagos State Allocation & C of O",

    button: "Book an Inspection",

    facility: "Property  Facilities",
  },
];
