import React from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { gsap } from "gsap";
import { useRef, useEffect } from "react";
import "../components/ProductDisplay.css";
import location1 from "../assets/services/resedential/locations/location0.png";
import location2 from "../assets/services/resedential/locations/location2.png";
import location3 from "../assets/services/resedential/locations/location3.png";

const Lands = () => {
  let devImageContainer = useRef();
  let developmentInteraction = gsap.timeline({ repeat: -1 });
  useEffect(() => {
    let devanime = gsap.context(() => {
      developmentInteraction
        .to(
          ".card1",
          {
            transform: "translateY(-20%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
          },
          1
        )
        .to(
          ".card3",
          {
            transform: "translateY(-20%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
          },
          1
        )
        .to(
          ".card1",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".card2",
          {
            transform: "translateY(-20%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".card3",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".card2",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 15,
          },
          3
        );
    }, devImageContainer);
    return () => devanime.revert();
  }, []);
  return (
    <div className="container mainCardContainer" style={{ marginTop: "100px" }}>    
      <h2>CURRENTLY NOW SELLING</h2>
      <div ref={devImageContainer} className=" cardContain">
        <div className="card card3">
          <img src={location3} alt="nature"></img>
          <h4>Hectares of Land</h4>
          <p>Hectares of Land</p>
          <p>
            {" "}
            <strong>N100 million /Hectare</strong>{" "}
          </p>
          <Link className="link" to="/pending-suite">
            Learn More
          </Link>
        </div>
      </div>

      <div className=" cardContainMobile">
        <div className="card card1">
          <img src={location1} alt="nature"></img>
          <h4>3-Bedroom Flats on 2 Floors</h4>
          <p>Fully fenced 8 units. It can accommodate more than 16 cars</p>

          <p>
            {" "}
            <strong>N45Million /Unit</strong>{" "}
          </p>

          <Link className="link" to="/properties">
            Learn More
          </Link>
        </div>
        <div className="card card2">
          <img src={location2} alt="nature"></img>
          <h4>5-Bedroom Flats on 2 Floors</h4>
          <p>
            Lounge area, relaxation area, mini swimming pool 2 parking spaces
            for each apartment.
          </p>
          <p>
            {" "}
            <strong>$1.2Million /Unit</strong>{" "}
          </p>
          <Link className="link" to="/luxury-properties">
            Learn More
          </Link>
        </div>
        <div className="card card3">
          <img src={location3} alt="nature"></img>
          <h4>Hectares of Land</h4>
          <p>Hectares of Land</p>
          <p>
            {" "}
            <strong>N100 million /Hectare</strong>{" "}
          </p>
          <Link className="link" to="/pending-suite">
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Lands;
