import starsImage from "../assets/Group 2780-5-stars.png";
const reviews = [
  {
    title: "Excellent Real Estate Company",
    body: "“First and foremost Maximum Luxury Apartments was absolutely brilliant to work with when selling home and landed property every step of the way they have been amazing. We felt they made everything so easy and stress free. There level of professionalism was exceptional and delivered on all that they promised. Maximum Luxury Apartments has gone over and above in all aspects in their dealings with us and they were very transparent in regards to the whole sales process. We couldn't be happier and Maximum Luxury Apartments Real Estate has lived up to all that it promised.”",
    name: "Dolapo Olalekan",
    date: "Feb 24, 2021",
    initials: "DO",
    star: starsImage,
  },
  {
    title: "Maximum Luxury Apartment",
    body: "Thank you so much for helping us purchase our Land property in your Estate. We also appreciate and loved our gift and it will look great in our outdoor living room - very thoughtful! Thank you once again and it was great dealing with you. We would definitely recommend Unlimited Homes Real Estate.",
    name: "Ayo Ayodele",
    date: "Aug 24, 2021",
    initials: "AA",
    star: starsImage,
  },
];

export default reviews;
