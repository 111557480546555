import React, { useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import { FaHouseChimneyWindow } from "react-icons/fa6";
//import projectImage1 from "../assets/project_and_development/image 123project-and-development.png";
//import projectImage2 from "../assets/project_and_development/image 124project-and-development.png";
import projectImage1 from "../assets/project_and_development/projectImg1.png";
import projectImage2 from "../assets/project_and_development/projectImg2.png";
import projectImage3 from "../assets/project_and_development/projectImg3.png";
import projectImage4 from "../assets/project_and_development/projectImg4.png";
import projectImage5 from "../assets/project_and_development/projectImg5.png";
import projectImage6 from "../assets/project_and_development/projectImg6.png";
import projectImage7 from "../assets/project_and_development/projectImg7.png";
import projectImage8 from "../assets/project_and_development/projectImg8.png";
import projectImage9 from "../assets/project_and_development/projectImg9.png";
import rowHouseImage from "../assets/Frame 2797about-row-house.png";
import rowHouseImage2 from "../assets/rowHouseImage2.png";

import jideAjanaPic from "../assets/Group 2801Jide-ajana.svg";
import values from "../content/values";
import rightQuote from "../assets/quotation_marks/Vector-quotes .png";
import leftQuote from "../assets/quotation_marks/Vector-quotes -1.png";
import reasons from "../content/why";
import Form from "../components/form";
import Naira from "../assets/Replacement.svg";
import Switch1 from "../assets/Switch1.png";
import Switch2 from "../assets/Switch2.png";
import Switch3 from "../assets/Switch3.png";
import Switch4 from "../assets/Switch4.png";
import Switch5 from "../assets/Switch5.png";
import Switch6 from "../assets/Switch6.png";
import Switch7 from "../assets/Switch7.png";
import Switch8 from "../assets/Switch8.png";
import Switch9 from "../assets/Switch9.png";

const About = () => {
  gsap.registerPlugin(ScrollTrigger);
  let aboutRow1Ref = useRef();
  let devImageContainer = useRef();
  let ourAimContainer = useRef();

  let developmentInteraction = gsap.timeline({ repeat: -1 });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    projectImage1,
    projectImage2,
    projectImage3,
    projectImage4,
    projectImage5,
    projectImage6,
    projectImage7,
    projectImage8,
    projectImage9,
  ];

  // Additional images for each main image
  //const additionalImages = [[projectImage8], [projectImage4], [projectImage7]];
  const additionalImages = [[projectImage8], [projectImage4], [projectImage7], [projectImage3], [projectImage9], [projectImage5], [projectImage1], [projectImage2]];

  const switchImages = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Use currentImageIndex to switch the images
  useEffect(() => {
    const imagesContainer = devImageContainer.current;
    imagesContainer.querySelector(".img1").src = images[currentImageIndex];
    imagesContainer.querySelector(".img2").src =
      images[(currentImageIndex + 1) % images.length];
    imagesContainer.querySelector(".img3").src =
      images[(currentImageIndex + 2) % images.length];
  }, [currentImageIndex]);

  // Additional images for each main image
  useEffect(() => {
    const imagesContainer = devImageContainer.current;
    const currentMainImage = imagesContainer.querySelector(
      `.img${currentImageIndex + 1}`
    );
    const additionalImagesArray = additionalImages[currentImageIndex];
    if (currentMainImage) {
      for (let i = 0; i < additionalImagesArray.length; i++) {
        currentMainImage.style.backgroundImage = `url(${additionalImagesArray[i]})`;
      }
    }
  }, [currentImageIndex, additionalImages]);

  useEffect(() => {
    const intervalRef = setInterval(switchImages, 5000);
    return () => clearInterval(intervalRef);
  }, []);

  useEffect(() => {
    let interaction = gsap.context(() => {
      gsap.to(".our-aim", {
        scrollTrigger: {
          trigger: ".our-aim",
          toggleActions: "restart pause resume none",
        },
        transform: "translateX(0%)",
        duration: 1,
        ease: "ease-in",
      });
    }, ourAimContainer);
    return () => interaction.revert();
  }, []);
  useEffect(() => {
    let ctx = gsap.context(() => {
      // var tl = gsap.timeline();
      gsap.to(
        ".col-1",
        {
          scrollTrigger: {
            trigger: ".col-1",
            toggleActions: "restart pause resume none",
          },
          transform: "translateX(0vw)",
          duration: 1,
          ease: "ease",
        },
        1
      );
      gsap.to(
        ".col-2",
        {
          scrollTrigger: {
            trigger: ".col-2",
            toggleActions: "restart pause resume none",
          },
          transform: "translateX(0vw)",
          duration: 1,
          ease: "ease",
        },
        1
      );
    }, aboutRow1Ref);
    return () => ctx.revert();
  }, []);

  useEffect(() => {
    let devanime = gsap.context(() => {
      developmentInteraction
        .to(
          ".img1",
          {
            transform: "translateY(-30%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
          },
          1
        )
        .to(
          ".img3",
          {
            transform: "translateY(-30%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
          },
          1
        )
        .to(
          ".img1",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".img2",
          {
            transform: "translateY(-30%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".img3",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".img2",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 15,
          },
          3
        );
    }, devImageContainer);
    return () => devanime.revert();
  }, []);

  document.addEventListener("DOMContentLoaded", function () {
    const imageContainer = document.querySelector(".image-container-toggle");
    let moveRight = true;

    function toggleImagePosition() {
      if (moveRight) {
        imageContainer.style.transform = "translateX(100px)";
      } else {
        imageContainer.style.transform = "translateX(0)";
      }
      moveRight = !moveRight;
    }
    setInterval(toggleImagePosition, 4000);
  });

  return (
    <section className="about">
      <section className="about-hero">
        <div className="container-about-hero">
          <h1>
            Affordable Luxury Housing{" "}
            <FaHouseChimneyWindow className="house-icon" /> With The Best Price
            <img src={Naira} alt="" class="image-container-toggle" />
            And Quality
          </h1>
        </div>
      </section>
      <section className="about-section">
        <span className="heading">
          <hr />
          <h2>About Us</h2>
        </span>
        <div className="row1" ref={aboutRow1Ref}>
          <img className="col-2" src={rowHouseImage2} alt="row-housing"></img>
          <p className="col-1">
            At MAXIMUM LUXURY APARTMENTS, nothing gets in the way of your needs.
            When you’re looking to buy land in the 5th largest city in Africa,
            Lagos state, Nigeria, whether residential or commercial, we will
            always treat you as our top priority. Our Maximum Luxury sales
            consultants are waiting to provide you with a premium service for
            buying your dream land – visit our office for site inspection or
            call us today!
          </p>
        </div>

        <div className="MissionVision">
          <h2>Mission & Vision</h2>
          <p>
            Our aim is to provide real estate development in lagos, Nigeria that
            afford us the opportunity of creating fully functional housing
            estates that open up and compliment government's efforts at
            providing affordable accommodation to our main target market.
          </p>
        </div>

        <div ref={ourAimContainer} className="project-Development">
          <span className="sub-heading">
            <hr />
            <h2>Projects And Developments</h2>
          </span>
          <p className="our-aim">
            Our aim is to provide real estate development in lagos, Nigeria that
            afford us the opportunity of creating fully functional housing
            estates that open up and compliment government's efforts at
            providing affordable accommodation to the our main target market -
            The middle income group.In developing our housing units we
            invariably provide environments that are refreshing alternatives to
            the stressful and sometimes chaotic conditions of a highly populated
            Lagos metropolis. The nearness of our estates to the Lagos Central
            Business District, Ikoyi & Victoria Island, invariably means a
            reduction of stress levels experienced by commuters who have to
            travel these distances to work every day.
          </p>
          <div ref={devImageContainer} className="img-container">
            <img className="img1" src={projectImage1} alt="estate-pics"></img>
            <img className="img2" src={projectImage2} alt="estate-pics"></img>
            <img className="img3" src={projectImage3} alt="estate-pics"></img>
          </div>
          {/* <div className="img-container1">
            <img className="img1" src={projectImage1} alt="estate-pics"></img>
            <img className="img2" src={projectImage2} alt="estate-pics"></img>
            <img className="img3" src={projectImage3} alt="estate-pics"></img>
          </div> */}
        </div>
        <span className="sub-heading">
          {" "}
          <hr />
          <h2>Objectives</h2>
        </span>
        <ul>
          <li>
            <span>
              <FiArrowRightCircle />
            </span>
            To develop really functional and commercial viable estates that will
            complement Government Housing <br /> Programme, towards increasing
            housing stock
          </li>
          <li>
            <span>
              <FiArrowRightCircle />
            </span>
            To produce model self-sustaining real estate developments for the
            medium class group of the society.
          </li>
          <li>
            <span>
              <FiArrowRightCircle />
            </span>
            To develop residental apartments that would serve as a key for Lagos
            state Government's economic blue <br /> print especially in respect
            to the accomodation needs of the various proposed industrial
            concerns along the
            <br /> lekki axis, such as the Lagos state Airport, Lekki Free Trade
            Zone etc.
          </li>
        </ul>
        <div className="lead-consultant">
          <h3>Meet Our Lead Consultant</h3>
          <div className="bio">
            <img src={jideAjanaPic} alt="Jide Ajana"></img>
            <p>
              As a driven and dedicated law entrepreneur, I am deeply passionate
              about making a positive impact in the spheres of immigration,
              business development and real estate. With years of experience in
              the field, I have honed my skills and expertise to help
              individuals, businesses, and communities navigate the complex
              legal landscape with ease.
              <br /> My unwavering commitment to upholding justice and fairness
              is what sets me apart from the competition. Whether I am helping
              immigrant families reunite, guiding aspiring entrepreneurs through
              the intricacies of setting up a business, or assisting clients
              with the complexities of real estate deals, I am always driven by
              a sense of purpose and meaning.
              <br /> I believe that legal services should be accessible to all,
              regardless of their social or economic background. To this end, my
              team and I work tirelessly to provide affordable and high-quality
              legal services to our clients, ensuring that they receive the best
              possible representation and support.
              <br /> If you are seeking a driven and experienced law
              entrepreneur who can help you navigate the complexities of
              immigration, business development, and real estate, look no
              further. I am excited to work with you and help you achieve your
              goals.
            </p>
          </div>
        </div>
      </section>
      <section className="value-proposition">
        <h2>OUR VALUE PROPOSITION</h2>
        <div className="slider-container">
          <div className="slider">
            {values.map((value, index) => {
              // Define an array of classes for different colors
              const colorClasses = [
                "blue-text",
                "purple-text",
                "red-text",
                "gray-text",
              ];
              // Use the modulo operator to cycle through the colors
              const colorClass = colorClasses[index % colorClasses.length];
              return (
                <div key={index} className="card">
                  <img alt="display" src={value.icon}></img>
                  <h4 className={`${colorClass}`}>{value.heading}</h4>
                  <p>{value.details}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="quote">
          {/* <img src={rightQuote} alt="quote"></img> */}
          <div className="quote-img-left">
            <img className="quote-img" src={rightQuote} alt="quote" />
          </div>
          <h3 style={{ color: "rgba(144, 60, 80, 1)" }}>
            Embrace the future with Maximum Luxury Apartments as your steadfast
            partner, connecting your ambitions to a world brimming with
            opportunities. Together, let's reach new heights of success and
            prosperity. Invest Abroad, Thrive in Nigeria, Excel Globally!
          </h3>
          <div className="quote-img-right">
            <img className="quote-img" src={leftQuote} alt="quote" />
          </div>
          {/* <img src={leftQuote} alt="quote"></img> */}
        </div>
      </section>
      <section className="why-max-luxury">
        <h3>Why Invest Or Buy Into Maximum Luxury Apartments</h3>
        <div className="why-max-container">
          {reasons.map((reason, index) => {
            if ((index + 1) % 2) {
              return (
                <div key={index} className="reason">
                  <h5>{reason.heading}</h5>
                  <div className="icon-detail">
                    <img src={reason.icon} alt="icon" className="icon"></img>
                    <p>{reason.message}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={index} className="reason">
                  <h5>{reason.heading}</h5>
                  <div className="icon-detail">
                    <p>{reason.message}</p>
                    <img src={reason.icon} alt="icon" className="icon"></img>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </section>

      <div className="why-max-container2">
        {reasons.map((reason, index) => (
          <div key={index} className="reason">
            <h5>{reason.heading}</h5>
            <div className="icon-detail">
              <img src={reason.icon} alt="icon" className="icon"></img>
              <p>{reason.message}</p>
            </div>
          </div>
        ))}
      </div>

      <section className="form-section">
        <div id="form-section" className="form-section-child">
          <h3>Book An Appointment With Us</h3>
          <p>
            We look forward to hearing from you. Email us to learn more about us
            and capabilities.
          </p>
          <div className="form-section-form">
            <Form width={"50%"} />
          </div>
        </div>
      </section>
    </section>
  );
};

export default About;
