import React from "react";
import Properties1 from "./Properties1";

const Index = () => {
  return (
    <div>
      <Properties1 />
    </div>
  );
};

export default Index;
