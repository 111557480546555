import React from "react";
import "./Page3.css";
import Page3New from "../assets/Page3New.svg";

const Page3 = () => {
  return (
    <div className="container Page3Main">
      <div className="Page3Parent">
        <h3>A Place Designed to Delight & Re-Energize</h3>
        <div className="Page3ParentDiv"></div>
      </div>
      <div className="Page3Child">
      <img src={Page3New} alt="" />
        <p>
          An expansive lake gently meanders from the heart of the island towards
          the lagoon, bordered by landscaped parks and open spaces designed for
          physical and mental wellness. An iconic new clubhouse overlooks the
          lake and will be a meeting place to indulge in a range of activities
          including swimming, tennis, dining or simply lounging.
        </p>  
      </div>  
    </div>
  );
};  

export default Page3;
