import React from "react";
import "./Page7.css";
import Page7Img from "../assets/Page7Img.svg";

const Page7 = () => {
  return (
    <div className="container Page7Main">

      <div className="Page7Parent">
        <h3>Star-Island Master Plan & Proposed Site </h3>

        <div className="Page7ParentDiv"></div>
      </div>

      <div className="Page7Parent">
        <div className="Page7Child1">
          <img src={Page7Img} alt="" />
        </div>
        <div className="Page7Child">  
          <p>
            The Star Island project is conceived with the specific goal of
            creating a fantastic contemporary up-market residential, mixed use
            and commercial use mega city on a reclaimed island in the calm
            waters of the Lagoon bordering the Lekki Phase 1 foreshore estate.
            The project, which accounts for hectares of land exclusive of the
            water features, involves the dredging of sand from the bed of the
            water body, the construction of an access land bridge and the
            creation of infrastructure to service the island.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Page7;
