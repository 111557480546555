import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { gsap } from "gsap";
import { useRef, useEffect } from "react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import Form from "./form";
import mainPic from "../assets/services/property-managment/mainPic2.png";
// import mainPic from "../assets/services/property-managment/Rectangle-property-managment-main.png";
import accesspic1 from "../assets/services/resedential/access/Rectangle-access-pics-1.png";
import accesspic2 from "../assets/services/resedential/access/Rectangle-access-pics.png";
import starIsland1 from "../assets/services/resedential/star0island/Rectangle-starisland-pics-1.png";
import starIsland2 from "../assets/services/resedential/star0island/Rectangle-starisland-pics-2.png";
import starIsland3 from "../assets/services/resedential/star0island/Rectangle-starisland-pics.png";
import starIsland4 from "../assets/services/resedential/star0island/Rectangle-starisland-pics-1.png";
import location1 from "../assets/services/resedential/locations/Rectangleexceptional-locations.png";
import location2 from "../assets/services/resedential/locations/Rectangleexceptional-locations-1.png";
import location3 from "../assets/services/resedential/locations/Rectangleexceptional-locations-2.png";
import MultiCarousel2 from "./Multicarousel2";
import ProductDisplay from "./ProductDisplay";
const PropertyManagment = () => {
  let devImageContainer = useRef();
  let developmentInteraction = gsap.timeline({ repeat: -1 });
  useEffect(() => {
    let devanime = gsap.context(() => {
      developmentInteraction
        .to(
          ".card1",
          {
            transform: "translateY(-20%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
          },
          1
        )
        .to(
          ".card3",
          {
            transform: "translateY(-20%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
          },
          1
        )
        .to(
          ".card1",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".card2",
          {
            transform: "translateY(-20%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".card3",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".card2",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 15,
          },
          3
        );
    }, devImageContainer);
    return () => devanime.revert();
  }, []);
  return (
    <div className="services-component">
      <img src={mainPic} alt="main"></img>
      <p>
        Property Management services cover a wide range of tasks to ensure the
        smooth operation of a building and the satisfaction of its residents or
        tenants. Our services include finding and selecting tenants, collecting
        rents, and ensuring compliance with contractual agreements. We also
        handle maintenance and repairs to maximize returns on investments. We
        pride ourselves on our ability to manage complexity and offer real time
        reporting for constant monitoring and control. Additionally, we provide
        facilities management to help clients save on operational and
        maintenance costs while meeting quality, health, safety and
        environmental standards
      </p>
      <div className="row-2">
        <div className="text">
          <h3>We Provide Construction Services</h3>
          <p>
            With experts in every part of the world, we are local everywhere,
            allowing us to walk alongside our clients at every stage of their
            journey. With innovative technology and unrivaled service, we ensure
            that your home is connected with buyers, locally and worldwide.
          </p>
        </div>
        <div className="images">
          <img src={accesspic2} alt="stack"></img>
          <img src={accesspic1} alt="stack"></img>
        </div>
      </div>
      <div className="row-3">
        <h3> Star Island Property Management Outlook</h3>
        <p>
          For those who seek an exceptional home and life, browse our video
          series catalogue.
        </p>

        <div>
          <MultiCarousel2 />
        </div>
      </div>
      <div className="row-4">
        {/* <h3>Our Available Properties for Sale</h3> */}

        <ProductDisplay />

        {/* <div ref={devImageContainer} className="card-container">
          <div className="card card1">
            <img src={location1} alt="nature"></img>
            <h4>Classique Suite</h4>
            <p>Exceptional Locations, Natures Views & Unraveled Lifestyles</p>
            <Link className="link" to="/properties">
              Learn More
            </Link>
          </div>
          <div className="card card2">
            <img src={location2} alt="nature"></img>
            <h4>Executive Suite</h4>
            <p>
              An ambitious exploration into high-end residential homes across
              the globe
            </p>
            <Link className="link" to="">
              Learn More
            </Link>
          </div>
          <div className="card card3">
            <img src={location3} alt="nature"></img>
            <h4>Pending Suite</h4>
            <p>
              Experience luxury homes like never before, all from the comfort of
              yours
            </p>
            <Link className="link" to="">
              Learn Mor
            </Link>
          </div>
        </div> */}
      </div>
      <div className="row-5">
        <h3>Book An Appointment With Us</h3>
        <p>
          We look forward to hearing from you. Email us to learn more about us
          and capabilities.
        </p>
        <Form width={"60%"} />
      </div>
    </div>
  );
};
export default PropertyManagment;
