import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaClock, FaEye, FaShareAlt, FaFacebook, FaTwitter, FaLinkedin, FaCopy, FaUser, FaComment } from 'react-icons/fa';
import client, { urlFor } from '../../sanityClient';
import './blogDetail.css';

const BlogDetail = () => {
    const [post, setPost] = useState(null);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const [shareResult, setShareResult] = useState('');
    const [comment, setComment] = useState('');
    const { id } = useParams();

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const result = await client.fetch(`
                    *[_type == "blogPost" && _id == $id][0]{
                        ...,
                        author->{name, image},
                        comments[]{author, text, createdAt}
                    }
                `, { id });
                
                // Initialize views if it doesn't exist, then increment
                await client
                    .patch(id)
                    .setIfMissing({views: 0})
                    .inc({views: 1})
                    .commit();
    
                // Fetch the updated post to get the new view count
                const updatedPost = await client.fetch(`
                    *[_type == "blogPost" && _id == $id][0]{
                        views
                    }
                `, { id });
                
                setPost({...result, views: updatedPost.views});
            } catch (error) {
                console.error('Error fetching blog post:', error);
            }
        };
    
        fetchPost();
    }, [id]);

    if (!post) return <div>Loading...</div>;

    const formatDetails = (details) => {
        const sentences = details.split('. ');
        const paragraphs = [];
        for (let i = 0; i < sentences.length; i += 3) {
            paragraphs.push(sentences.slice(i, i + 3).join('. ') + '.');
        }
        return paragraphs;
    };

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        const newComment = {
            _type: 'comment',
            author: 'Anonymous', // You can replace this with actual user name if you have authentication
            text: comment,
            createdAt: new Date().toISOString(),
        };

        try {
            await client
                .patch(id)
                .setIfMissing({ comments: [] })
                .append('comments', [newComment])
                .commit();

            // Refetch the post to get the updated comments
            const updatedPost = await client.fetch(`
                *[_type == "blogPost" && _id == $id][0]{
                    ...,
                    author->{name, image},
                    comments[]{author, text, createdAt}
                }
            `, { id });
            setPost(updatedPost);
            setComment('');
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    const handleShare = (platform) => {
        const url = encodeURIComponent(window.location.href);
        const title = encodeURIComponent(post.title);
        let shareUrl;

        switch (platform) {
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
                break;
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`;
                break;
            case 'copy':
                navigator.clipboard.writeText(window.location.href)
                    .then(() => setShareResult('Link copied to clipboard!'))
                    .catch(err => setShareResult('Failed to copy link.'));
                setTimeout(() => setShareResult(''), 3000);
                return;
            default:
                return;
        }

        if (shareUrl) {
            window.open(shareUrl, '_blank', 'noopener,noreferrer');
        }

        setShowShareMenu(false);
    };

    return (
        <div className='blog-detail'>
            {post && (
                <>
                    <article className="blog-detail__heading">
                        <h1 className="blog-detail__title">{post.title}</h1>
                        <div className="article-meta">
                            <p className="author">
                                <FaUser /> By {post.author?.name}
                            </p>
                            <div className="meta-info">
                                <span className="date">
                                    <FaClock /> {new Date(post._createdAt).toLocaleDateString()}
                                </span>
                                <span className="views">
                                    <FaEye /> Views: {post.views || 0}
                                </span>
                            </div>
                        </div>
                    </article>
                    <div className='blog-detail__content'>
                        {post.image && <img loading="lazy" className="blog-detail__image" src={urlFor(post.image).width(800).fit('max').url()} alt={post.title} />}
                        <div className='details'>
                            {formatDetails(post.details).map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                        </div>
                        <div className="blog-detail__share-container">
                            <div className="blog-detail__share" onClick={() => setShowShareMenu(!showShareMenu)}>
                                <FaShareAlt />
                                <p>Share</p>
                            </div>
                            {showShareMenu && (
                                <div className="share-menu">
                                    <button onClick={() => handleShare('facebook')}><FaFacebook /> Facebook</button>
                                    <button onClick={() => handleShare('twitter')}><FaTwitter /> Twitter</button>
                                    <button onClick={() => handleShare('linkedin')}><FaLinkedin /> LinkedIn</button>
                                    <button onClick={() => handleShare('copy')}><FaCopy /> Copy Link</button>
                                </div>
                            )}
                        </div>
                        {shareResult && <div className="share-result">{shareResult}</div>}
                    </div>
                    <div className="blog-detail__comments">
                        <h3>Comments ({post.comments?.length || 0})</h3>
                        {post.comments?.map((comment, index) => (
                            <div key={index} className="comment">
                                <p className="comment-author">{comment.author}</p>
                                <p className="comment-text">{comment.text}</p>
                                <p className="comment-date">{new Date(comment.createdAt).toLocaleString()}</p>
                            </div>
                        ))}
                        <form onSubmit={handleCommentSubmit} className="comment-form">
                            <textarea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Write a comment..."
                                required
                            />
                            <button type="submit" className="search-btn">Submit Comment</button>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
};

export default BlogDetail;