import React from "react";
import Index from "../properties/Index";

const Properties = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default Properties;
