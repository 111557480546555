import React from "react";
import Carousel from "react-multi-carousel";
import "./Page10.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1200, min: 922 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 922, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

const CarouselProps = ({ data, headLine }) => {
  return (
    <div className="Page10GrandMain">
      <div className="container Page10Main">
        <h3> {headLine} </h3>
        <div className="Page10Parent">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            arrows={true}
            infinite={true}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side. infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={1000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[
              "tablet",
              "mobile",
              "desktop",
              "superLargeDesktop",
            ]}
            // deviceType={deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {data &&
              data.map((d, index) => (
                <div key={index} className="page10-image">
                  <img src={d.image} alt="" />

                  <div className="page10Text">
                    <h6> {d.header} </h6>
                    <p> {d.text} </p>
                    <div className="page10Childrent">
                      <p> {d.texta} </p>
                      <p> {d.textb} </p>
                      <p> {d.textc} </p>
                    </div>
                  </div>
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default CarouselProps;
