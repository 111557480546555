import React from "react";
import "./Page1.css";
import ProjectHero from "../assets/ProjectHero.svg";

const Page1 = () => {
  return (
    <div className="Page1Main">
      <div className="container">
        <h2>
          Welcome to Star Island A charming and vibrant new residential
          neighborhood designed to change the living for Lagosians
        </h2> 
      </div>
    </div>
  );
};  

export default Page1;
