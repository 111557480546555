import Carousel from "react-multi-carousel";
import { droneImage } from "../content/droneViews";

const MultiCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1200, min: 922 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 922, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 2,
    },
  };

  return (
    <div className="container">
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        arrows={true}
        infinite={true}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side. infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={1000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={[
          "tablet",
          "mobile",
          "superLargeDesktop",
          "desktop",
        ]}
        // deviceType={deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {droneImage.map((d, index) => (
          <div key={index} className="carouse-image">
            <img src={d.image} alt />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MultiCarousel;
