import React from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Button from "../components/button";
import { useState, useRef, useEffect } from "react";
import { FiArrowUpRight, FiArrowRightCircle } from "react-icons/fi";
import propertisetype from "../content/propertiseType";
import heroBackground from "../content/herobackGround";
// import rowHouseImage from "../assets/about-image.png";
import rowHouseImage from "../assets/rowHouseImage.png";
import projectImage1 from "../assets/project_and_development/projectImg1.png";
import projectImage2 from "../assets/project_and_development/projectImg2.png";
import projectImage3 from "../assets/project_and_development/projectImg3.png";
import projectImage4 from "../assets/project_and_development/projectImg4.png";
import projectImage5 from "../assets/project_and_development/projectImg5.png";
import projectImage6 from "../assets/project_and_development/projectImg6.png";
import projectImage7 from "../assets/project_and_development/projectImg7.png";
import projectImage8 from "../assets/project_and_development/projectImg8.png";
import projectImage9 from "../assets/project_and_development/projectImg9.png";
import serviceImage from "../assets/serviceImage.png";
import Housing1 from "../assets/Housing1.png";
import Housing2 from "../assets/Housing2.png";
import Housing3 from "../assets/Housing3.png";
// import Housing4 from "../assets/Housing4.png";
import Housing5 from "../assets/Housing5.png";
import estate1 from "../assets/ongoing grid/Frame 2802-ongoing-grid.png";
// import estate2 from "../assets/ongoing grid/Frame 2803-ongoing-grid.png";
import estate2 from "../assets/ongoing grid/estate2.png";
import estate3 from "../assets/ongoing grid/estate3.png";

import values from "../content/values";
import rightQuote from "../assets/quotation_marks/Vector-quotes .png";
import leftQuote from "../assets/quotation_marks/Vector-quotes -1.png";
import reviews from "../content/reviews";
import vidoePoster from "../assets/Frame 2803-video-poster.png";
import { FaHouseChimneyWindow } from "react-icons/fa6";
import { GrStar } from "react-icons/gr";
import Faqs from "../faq/Faqs";
import MultiCarousel from "../components/MultiCarousel";
import "react-multi-carousel/lib/styles.css";
import MultiCarousel1 from "../components/MultiCarousel1";

import Naira from "../assets/Replacement.svg";
import { Link } from "react-router-dom";
import Quality from "../assets/Quality.svg";
import VideoFile from "../components/VideoFile";
import ProductDisplay from "../components/ProductDisplay";

const Homepage = () => {
  gsap.registerPlugin(ScrollTrigger);
  let aboutRow1Ref = useRef();
  let devImageContainer = useRef();
  let ourAimContainer = useRef();
  let developmentInteraction = gsap.timeline({ repeat: -1 });
  const [transform, setTransform] = useState(0);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    projectImage1,
    projectImage2,
    projectImage3,
    projectImage4,
    projectImage5,
     projectImage6,
    projectImage7,
    projectImage8,
  ];

  // Additional images for each main image
  //const additionalImages = [[projectImage8], [projectImage4], [projectImage7]];
  const additionalImages = [[projectImage1], [projectImage2], [projectImage3], [projectImage4], [projectImage5], [projectImage6], [projectImage7], [projectImage8], [projectImage9]];

  const switchImages = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const devanime = gsap.timeline({ repeat: -1 });
    devanime
      .to(
        ".img1",
        {
          transform: "translateY(-30%)",
          ease: "linear",
          duration: 0.7,
          delay: 5,
          background: `url(${images[0]})`,
        },
        1
      )
      .to(
        ".img3",
        {
          transform: "translateY(-30%)",
          ease: "linear",
          duration: 0.7,
          delay: 5,
        },
        1
      )
      .to(
        ".img1",
        {
          transform: "translateY(0%)",
          ease: "linear",
          duration: 0.7,
          delay: 10,
        },
        2
      )
      .to(
        ".img2",
        {
          transform: "translateY(-30%)",
          ease: "linear",
          duration: 0.7,
          delay: 10,
        },
        2
      )
      .to(
        ".img3",
        {
          transform: "translateY(0%)",
          ease: "linear",
          duration: 0.7,
          delay: 10,
        },
        2
      )
      .to(
        ".img2",
        {
          transform: "translateY(0%)",
          ease: "linear",
          duration: 0.7,
          delay: 15,
          onComplete: switchImages,
        },
        3
      );

    return () => devanime.revert();
  }, []);

  // Use currentImageIndex to switch the images
  useEffect(() => {
    const imagesContainer = devImageContainer.current;
    imagesContainer.querySelector(".img1").src = images[currentImageIndex];
    imagesContainer.querySelector(".img2").src =
      images[(currentImageIndex + 1) % images.length];
    imagesContainer.querySelector(".img3").src =
      images[(currentImageIndex + 2) % images.length];
  }, [currentImageIndex]);

  // Additional images for each main image
  useEffect(() => {
    const imagesContainer = devImageContainer.current;
    const currentMainImage = imagesContainer.querySelector(
      `.img${currentImageIndex + 1}`
    );
    const additionalImagesArray = additionalImages[currentImageIndex];
    if (currentMainImage) {
      for (let i = 0; i < additionalImagesArray.length; i++) {
        currentMainImage.style.backgroundImage = `url(${additionalImagesArray[i]})`;
      }
    }
  }, [currentImageIndex, additionalImages]);

  useEffect(() => {
    const devanime = gsap.timeline({ repeat: -1 });
    devanime
      .to(
        ".img1",
        {
          transform: "translateY(-30%)",
          ease: "linear",
          duration: 0.7,
          delay: 5,
          background: `url(${images[0]})`,
        },
        1
      )
      .to(
        ".img3",
        {
          transform: "translateY(-30%)",
          ease: "linear",
          duration: 0.7,
          delay: 5,
        },
        1
      )
      .to(
        ".img1",
        {
          transform: "translateY(0%)",
          ease: "linear",
          duration: 0.7,
          delay: 10,
        },
        2
      )
      .to(
        ".img2",
        {
          transform: "translateY(-30%)",
          ease: "linear",
          duration: 0.7,
          delay: 10,
        },
        2
      )
      .to(
        ".img3",
        {
          transform: "translateY(0%)",
          ease: "linear",
          duration: 0.7,
          delay: 10,
        },
        2
      )
      .to(
        ".img2",
        {
          transform: "translateY(0%)",
          ease: "linear",
          duration: 0.7,
          delay: 15,
          onComplete: switchImages,
        },
        3
      );

    return () => devanime.revert();
  }, []);

  useEffect(() => {
    const intervalRef = setInterval(switchImages, 5000);
    return () => clearInterval(intervalRef);
  }, []);

  const slideBackgroundImage = () => {
    if (transform >= 0 || transform !== -3960) {
      setTransform(transform - 1320);
    } else if (transform === -3960) {
      setTransform(0);
    }
  };
  let intervalRef = useRef();
  useEffect(() => {
    let ctx = gsap.context(() => {
      // var tl = gsap.timeline();
      gsap.to(
        ".col-1",
        {
          scrollTrigger: {
            trigger: ".col-1",
            toggleActions: "restart pause resume none",
          },
          transform: "translateX(0vw)",
          duration: 1,
          ease: "ease",
        },
        1
      );
      gsap.to(
        ".col-2",
        {
          scrollTrigger: {
            trigger: ".col-2",
            toggleActions: "restart pause resume none",
          },
          transform: "translateX(0vw)",
          duration: 1,
          ease: "ease",
        },
        1
      );
    }, aboutRow1Ref);
    return () => ctx.revert();
  }, []);
  useEffect(() => {
    let devanime = gsap.context(() => {
      developmentInteraction
        .to(
          ".img1",
          {
            transform: "translateY(-30%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
            background:
              "url(./assets/drone images/image 132-drone-images-1.png)",
          },
          1
        )
        .to(
          ".img3",
          {
            transform: "translateY(-30%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
          },
          1
        )
        .to(
          ".img1",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".img2",
          {
            transform: "translateY(-30%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".img3",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".img2",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 15,
          },
          3
        );
    }, devImageContainer);
    return () => devanime.revert();
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(slideBackgroundImage, 3000);
    return () => clearInterval(intervalRef.current);
  });
  useEffect(() => {
    let interaction = gsap.context(() => {
      gsap.to(".our-aim", {
        scrollTrigger: {
          trigger: ".our-aim",
          toggleActions: "restart pause resume none",
        },
        transform: "translateX(0%)",
        duration: 1,
        ease: "ease-in",
      });
    }, ourAimContainer);
    return () => interaction.revert();
  }, []);

  document.addEventListener("DOMContentLoaded", function () {
    const imageContainer = document.querySelector(".image-container-toggle");
    let moveRight = true;

    function toggleImagePosition() {
      if (moveRight) {
        imageContainer.style.transform = "translateX(100px)";
      } else {
        imageContainer.style.transform = "translateX(0)";
      }
      moveRight = !moveRight;
    }
    setInterval(toggleImagePosition, 4000);
  });

  return (
    <main className="homepage">
      <section className="hero">
        <h1>
          Affordable Luxury Housing{" "}
          <FaHouseChimneyWindow className="house-icon" /> With <br /> The Best
          Price
          {/* <div class="image-container-toggle"> */}{" "}
          <img src={Naira} alt="" class="image-container-toggle" />
          {/* </div> */}
          And Quality
        </h1>
        <p>
          We partake in construction and development processes from start to
          finish by providing real estate <br /> development solutions to our
          clients.
        </p>
        <div className="carousel-container">
          <div
            className="slider"
            style={{ transform: `translateX(${transform}px)` }}
          >
            {heroBackground.map((background, index) => {
              return <img key={index} alt="hero" src={background.image}></img>;
            })}
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="editedAbout-section">
          <h2>About Maximum Luxury Apartments</h2>
          <div className="editedAbout-section-hr">{/* <hr /> */}</div>
        </div>

        <div className="row1" ref={aboutRow1Ref}>
          <img className="col-2" src={rowHouseImage} alt="row-housing"></img>
          <p className="col-1">
            At MAXIMUM LUXURY APARTMENTS, nothing gets in the way of your needs.
            When you’re looking to buy land in the 5th largest city in Africa,
            Lagos state, Nigeria, whether residential or commercial, we will
            always treat you as our top priority. Our Maximum Luxury sales
            consultants are waiting to provide you with a premium service for
            buying your dream land – visit our office for site inspection or
            call us today!
          </p>
        </div>
        <div ref={ourAimContainer} className="project-Development">
          <span className="sub-heading">
            <hr />
            <h2>Projects And Developments</h2>
          </span>
          <p className="our-aim">
            Our aim is to provide real estate development in lagos, Nigeria that
            afford us the opportunity of creating fully functional housing
            estates that open up and compliment government's efforts at
            providing affordable accommodation to our main target market - “
            middle-income group”. In developing our housing units we invariably
            provide environments that are refreshing alternatives to the
            stressful and sometimes chaotic conditions of a highly populated
            Lagos metropolis. The nearness of our estates to the Lagos Central
            Business District, Ikoyi & Victoria Island, invariably means a
            reduction of stress levels experienced by commuters who have to
            travel these distances to work every day.
          </p>

          <div ref={devImageContainer} className="img-container">
            <img
              className="img1"
              loading="lazy"
              src={projectImage1}
              alt="estate-pics"
              key={0}
            ></img>
            <img
              className="img2"
              loading="lazy"
              src={projectImage2}
              alt="estate-pics"
              key={1}
            ></img>
            <img
              loading="lazy"
              className="img3"
              src={projectImage3}
              alt="estate-pics"
              key={2}
            ></img> 
          </div>

          {/* <div className="img-container1">
            <img className="img1" src={projectImage1} alt="estate-pics"></img>
            <img className="img2" src={projectImage2} alt="estate-pics"></img>
            <img className="img3" src={projectImage3} alt="estate-pics"></img>
          </div> */}
        </div>
        <span className="sub-heading">
          {" "}
          <hr />
          <h2>Objectives</h2>
        </span>
        <ul>
          <li>
            <span>
              <FiArrowRightCircle />
            </span>
            To develop really functional and commercial viable estates that will
            complement Government Housing <br /> Programme, towards increasing
            housing stock
          </li>
          <li>
            <span>
              <FiArrowRightCircle />
            </span>
            To produce model self-sustaining real estate developments for the
            medium class group of the society.
          </li>
          <li>
            <span>
              <FiArrowRightCircle />
            </span>
            To develop residental apartments that would serve as a key for Lagos
            state Government's economic blue <br /> print especially in respect
            to the accomodation needs of the various proposed industrial
            concerns along the
            <br /> lekki axis, such as the Lagos state Airport, Lekki Free Trade
            Zone etc.
          </li>
        </ul>

        <button>
          <Link to="/about">
            More About Us
            <FiArrowUpRight className="arrw" />
          </Link>
        </button>
      </section>
      <section className="services-section">
        <span className="sub-heading">
          <hr />
          <h2>Our Services</h2>
        </span>
        <div className="row-1">
          <div className="col-1">
            <h4>
              <b>Live</b> the life you deserve By
              <br /> Creating <b>wealth</b> through <b>property</b>
            </h4>
            <p>
              MAXIMUM LUXURY APARTMENTS offers diverse range of real estate and
              investment opportunities to cater to your residential and
              commercial needs. Our team of professionals is well-versed in
              local markets, providing you with valuable insights and
              personalized solutions.
            </p>
            <button className="more-btn">
              <Link to="/services">More</Link>
            </button>
          </div>

          <div className="col-2">
            <img
              className="sliSer-1"
              src={serviceImage}
              alt="fancy-house"
            ></img>
            <img className="sliSer-2" src={Housing2} alt="fancy-house"></img>
            <img className="sliSer-3" src={Housing1} alt="fancy-house"></img>
            <img className="sliSer-4" src={Housing3} alt="fancy-house"></img>
            {/* <img className="sliSer-5" src={Housing5} alt="fancy-house"></img> */}
          </div>
        </div>

        <div className="img-container row-2">
          {propertisetype.map((types, index) => {
            return (
              <div className="image" key={index}>
                <img alt="property type" src={types.image}></img>
                <p>{types.caption}</p>
              </div>
            );
          })}
        </div>
        <div className="MultiCarousel1Child">
          <MultiCarousel1 />
        </div>
      </section>

      <div>
        <ProductDisplay />
      </div>

      <section className="project-section">
        <h2>
          Ongoing Projects
          <hr />
        </h2>
        <div className="banner">
          <h4>
            Star <GrStar className="icon" /> Island Lagos
          </h4>
          <p>
            <hr />
            An Island with a Difference
            <hr />
          </p>
          {/* <Link> */}
          {/* <div class="nav-item contact-button"> */}
          <button>
            <Link to="/project"> Learn more</Link>
          </button>
          {/* </div> */}
          {/* </Link> */}
          {/* <Link to="/form">
            <Button label="Make a Reservation" />
          </Link> */}
        </div>
        <p>
          If you desire serenity, luxury, nature and lifestyle. Owning a plot in
          STAR ISLAND brings you the maximum satisfaction to your desires.
          Imagine having a serene environment sandwiched in nature's beautiful
          and rich formations with exciting world experiences on an Island,
          every single day will be a roller coaster journey for you and your
          loved ones.
          <br /> STAR ISLAND Lagos is a city of Glitz and Glamour where every
          single moment is soothing to the soul and body. The estate gives
          owners and visitors a beautiful coaster retreat and makes everyday
          looks like a holiday in the moon. An Island with a difference.
        </p>
        <div className="grid-container parent">
          <img src={estate1} alt="estate"></img>
          <img className="grid-img-2" src={estate2} alt="estate"></img>
          <p>
            Step into a world of contemporary design where luxury emanates from
            different angles. This project features a stylish and sophisticated
            design with quality fittings and beautiful finishing. Aidaville
            Courts will offer luxury benefits that will enhance your lifestyle
            in the hustle and bustle of Lagos and bring you tranquility,
            convenience and peace of mind.
          </p>
          <img className="grid-img-3" src={estate3} alt="estate"></img>
        </div>

        <div className="row-1">
          <ul className="col">
            <li>
              <span>Location: </span>Lekki Phase One beside orange island
            </li>
            <li>
              <span>Title: </span>C of O Status
            </li>
            <li>
              <span>Price: </span>N90M Per 500 SQ.M for the 1st 20/30 Plots
            </li>
          </ul>
          <ul className="col">
            <li>
              <span>Land Size: </span>Approximately 100 Hectares.
            </li>
            <li>
              <span>Current State: </span>Sand Fill Work in progress
            </li>
          </ul>
        </div>
      </section>

      <section className="features-and-ammenities">
        <h2 className="sub-heading">
          <hr />
          Features and Amenities
        </h2>
        <div className="row-1">
          <ul className="col">
            <li>Spacious Apartments</li>
            <li>Borehole&water treatment plant</li>
            <li>24 Hours Security</li>
            <li>E-services Intercom, Cable TV and Internet connectivity</li>
          </ul>
          <ul className="col">
            <li>Drainage system</li>
            <li>Paved Road Network</li>
            <li>Landscaping and perimeter fencing</li>
            <li>Dedicated Transformer and full standby power supply</li>
          </ul>
          <ul className="col">
            <li>Swimming pool.</li>
            <li>Parking facilities</li>
            <li>Security Lights</li>
          </ul>
        </div>
        <div className="btn-container">
          {/* <Link to="/form">
            <Button className="btn" label="Book an Inspection" />
          </Link> */}
          <div class="nav-item contact-button">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Book an Inspection
            </button>
          </div>
        </div>
      </section>

      <section className="droneview">
        <h3>Drone View Of Star Island</h3>
        {/* width="320" height="240" */}
        <video width="320" height="240" controls>
          <source src="Videos/Video.mp4" type="video/mp4" />
          <source src="Videos/Video.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>

        <div className="carousel-parent">
          <MultiCarousel />
        </div>
        <button>
          <Link to="/project">
            More Projects <FiArrowUpRight className="arrw" />
          </Link>
        </button>
      </section>

      <section className="value-proposition">
        <h2>OUR VALUE PROPOSITION</h2>
        <div className="slider-container">
          <div className="slider">
            {values.map((value, index) => {
              // Define an array of classes for different colors
              const colorClasses = [
                "blue-text",
                "purple-text",
                "red-text",
                "gray-text",
              ];
              // Use the modulo operator to cycle through the colors
              const colorClass = colorClasses[index % colorClasses.length];
              return (
                <div key={index} className="card">
                  <img alt="display" src={value.icon}></img>
                  <h4 className={`${colorClass}`}>{value.heading}</h4>
                  <p>{value.details}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="quote">
          <div className="quote-img-left">
            <img className="quote-img" src={rightQuote} alt="quote" />
          </div>
          <h3>
            Embrace the future with Maximum Luxury Apartments as your steadfast
            partner, connecting your ambitions to a world brimming with
            opportunities. Together, let's reach new heights of success and
            prosperity. Invest Abroad, Thrive in Nigeria, Excel Globally!
          </h3>
          <div className="quote-img-right">
            <img className="quote-img" src={leftQuote} alt="quote" />
          </div>
        </div>
      </section>

      <section className="client-review">
        <h2>Our Client Reviews</h2>
        <div className="review-container">
          {reviews.map((review, index) => {
            return (
              <div key={index} className="review">
                <div className="star">
                  <h6>{review.title}</h6>
                  <img src={review.star} alt="stars"></img>
                </div>

                <p>{review.body}</p>
                <div className="name-container">
                  <div className="icon">{review.initials}</div>
                  <div className="name">
                    <h5>{review.name}</h5>
                    <p>{review.date}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <div className="parentFaq-main">
        <Faqs />
      </div>
    </main>
  );
};

export default Homepage;
