import React from "react";
import PropertiesMainImage from "../../src/assets/PropertiesMainImage.png";
import PropertiesMainImage1 from "../../src/assets/PropertiesMainImage1.png";
import PropertiesMainImage2 from "../../src/assets/PropertiesMainImage2.png";
import PropertiesMainImage3 from "../../src/assets/PropertiesMainImage3.png";
import PropertiesMainImage4 from "../../src/assets/PropertiesMainImage4.png";
import PropertiesProps from "./PropertiesProps";
import bell from "../assets/bell.png";

const Properties1 = () => {
  return (
    <div>
      <PropertiesProps data={data} />
    </div>
  );
};

export default Properties1;

const data = [
  {
    id: 1,
    mainImage: PropertiesMainImage,
    image1: PropertiesMainImage4,
    image2: PropertiesMainImage1,
    image3: PropertiesMainImage2,
    image4: PropertiesMainImage3,
    propertyType: "3-Bedroom Flats on 2 Floors",
    value: "#45 million naira",
    value2: "unit",
    squareMeter: "1,152.56 sqm",
    space: "Spacious Room",
    sitting: "Sitting Space",
    sittingNo: "4.8",
    bell: bell,
    body: "Fully fenced 8 units of 3-bedroom flats on a block of 2 floors(ground floor, first floor & second floor). It can accommodate more than 16 cars",
    location:
      "Scheme II, Along Abraham Adesanya Estate/Ogombo Road, Lekki, Lagos state.",
    Areaofland: "",
    button: "Book an Inspection",

    facility: "Property  Facilities",
    facility1: "Free wifi",
    facility2: "Room service",
    facility3: "Free Kitchenette",
    facility4: "Mini bar",
    facility5: "King sized bed",
    facility6: "Bath",
    facility7: "Double Sink",
    facility8: "Shower",
  },
];
