import image1 from "../assets/services carousel/Image-services-carousel-1.png";
import image2 from "../assets/services carousel/Image-services-carousel-2.png";
import image3 from "../assets/services carousel/Image-services-carousel-3.png";
import image4 from "../assets/services carousel/Image-services-carousel-4.png";
import image5 from "../assets/services carousel/Image-services-carousel-5.png";

const propertisetype = [
  { image: image1, caption: "Residential Properties" },
  { image: image2, caption: "Commercial Properties" },
  { image: image3, caption: "Property Investment Consultancy" },
  { image: image4, caption: "Property Management Services" },
  { image: image5, caption: "Real Estate Legal Assistance" },
];

export default propertisetype;
