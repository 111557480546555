import React from "react";
import CarouselProps from "./CarouselProps";
import Page10a from "../assets/Page14a.png";
import Page10b from "../assets/Page14b.png";
import Page10c from "../assets/Page14c.png";
import Page10d from "../assets/Page14d.png";
import Page10e from "../assets/Page14e.png";
import Page10f from "../assets/Page14f.png";
import Page10g from "../assets/Page14g.png";
import Page10h from "../assets/Page14h.png";
import Page10i from "../assets/Page14i.png";

const Page14 = () => {
  const data = [
    {
      image: Page10a,
      header: "Shops ",
      text: "Easing the daily life of residents, a convenience store will offer all essentials items at your doorstep.",
    },
    {
      image: Page10b,
      header: "Coffee and Conservation",
      text: "Grab a coffee on a business meeting or simply with friends at the island’s many cafés",
    },
    {
      image: Page10c,
      header: "Delectable Flavours",
      text: "Easing the daily life of residents, a convenience store will offer all essentials items at your doorstep. Local and international restaurants, covering diverse cuisines, will satiate all food lovers.",
    },
    {
      image: Page10d,
      header: "Styled & Polished",
      text: "A beauty salon on site will satiate desires for pampering treatments and grooming.",
    },
    {
      image: Page10e,
      header: "Lake Side Walk",
      text: "A spot of scenic beauty to enjoy a summer’s day by cooling off with a swim, boating or peaceful walks along the banks",
    },
    {
      image: Page10f,
      header: "The Joy of Health &  Wealth Walk",
      text: "Satiating desire for wellness, the waterfront, lush parks and landscaped thoroughfares will offer healthy spaces to run, exercise or simply relax.",
    },
    {
      image: Page10g,
      header: "Playground & Pitches",
      text: "Satiating desire for wellness, the waterfront, lush parks and landscaped thoroughfares will offer healthy spaces to run, exercise or simply relax. Orange Island’s many parks will revitalize children and provide spaces to play.",
    },
    {
      image: Page10h,
      header: "Living in Style",
      text: "Star Island’s new community comprises of your beautifully designed villas, each reflecting the elegant individual style of its owner.",
    },
    {
      image: Page10i,
      header: "Marina & Yacht Club ",
      text: "The Marina, located to the North of the island, will be home to a sophisticated yacht club surrounded by a bustling retail promenade – a key draw for residents and visitors alike",
    },
  ];

  const headLine = "Close Attraction ";
  return (
    <div>
      <CarouselProps data={data} headLine={headLine} />
    </div>
  );
};

export default Page14;
