import React from "react";
import "./Page11.css";
import Page11Img from "../assets/Page11.svg";

const Page11 = () => {
  return (
    <div className="container Page11Main">
      <div className="Page11Parent">
        <h3>Star-Island Our Team</h3>

        <div className="Page11ParentDiv"></div>
      </div>

      <div className="Page11Child">
        <img src={Page11Img} alt="" />

        <p>
          <strong>
            OUR TEAM TREATS EVERY FACET OF YOUR VISION WITH THE UTMOST CARE,
            IMPORTANCE, AND EXPERTISE.
          </strong>{" "}
          <br />
          Bringing over 30 years of combined experience and expertise, Maximum
          Luxury Company is steered by the finest of Nigeria’s professionals.
          Built on values of trust and integrity in everything we do, it is what
          gives our clients, colleagues, suppliers, partners and investors the
          confidence to work with us.
        </p>
      </div>
    </div>
  );
};

export default Page11;
