import React, { useState, useEffect } from 'react';
import { FaUser, FaEye, FaComment } from 'react-icons/fa';
import './blogpages.css';
import client, { urlFor } from '../../sanityClient';
import { Link } from 'react-router-dom';

function Blog() {
  const [selectedImage, setSelectedImage] = useState(0);
  const [allImages, setAllImages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [blogPosts, setBlogPosts] = useState([]);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setInputText(e.target.value);
  };

  // Function to truncate text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const fetchContent = async () => {
    try {
      const images = await client.fetch(`*[_type == "heroImage"]{image}`);
      const posts = await client.fetch(`
        *[_type == "blogPost"]{
          _id, 
          title, 
          details, 
          link, 
          image,
          author->{name, image},
          views,
          "commentCount": count(comments)
        }
      `);
      console.log("posts", posts[1]);
      console.log("fetched posts:", posts);
      setAllImages(images.map(img => img.image? urlFor(img.image).url() : ''));
      setBlogPosts(posts);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchContent();
    const interval = setInterval(() => {
      setSelectedImage((prev) => (prev < allImages.length - 1 ? prev + 1 : 0));
    }, 5000);
    return () => clearInterval(interval);
  }, [allImages.length]);

  return (
    <main className="blogpage">
      {/* {error && <div className="error">Error: {error}</div>} */}
      <section className="blog_heros">
        <div className="blog-hero">
          {allImages.length > 0 && (
            <img loading="lazy" width="100%" height="100%" src={allImages[selectedImage]} alt="Hero" />
          )}
          <div className="blog-description">
            <p>Get latest news updates on our blog</p>
          </div>
        </div>
      </section>
      <section className="blog-msg-search">
        <div className="wrapper">
          {/* <div className="container"> */}
            <div className="blog-msg">
              <div className="keep-up">
                <h3>Want to keep up with our blog?</h3>
                <p>Our latest blog updates right inside your inbox</p>
              </div>
              <div className="blog-search">
                <div className="form-elements">
                  <input
                    type="text"
                    className="input-element"
                    placeholder="your email"
                    onChange={handleChange}
                    value={inputText}
                    style={{ paddingLeft: '2rem' }}
                  />
                  <button className="search-btn">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </section>
      <section className="blog-post">
        <div className="wrapper">
          <div className="container">
            <div className="post-container" style={{padding: "20px"}}>
              {/* <h2>Blog Posts</h2> */}
              <div className="blog-list">
                {blogPosts.map((post, index) => (
                  <div key={index} className="card-content">
                    <div className="blog-cards">
                      <img src={urlFor(post.image).url()} alt="card image" />
                      <div className="blog-card">
                        <h3 className="post-title">{post.title}</h3>
                        <p className="post-details">{truncateText(post.details, 150)}</p>
                        {/* <div className="post-meta">
                          <span className="author"><FaUser /> {post.author.name || "Anonymous"}</span>
                          <span className="views"><FaEye /> {post.views}</span>
                          <span className="comments"><FaComment /> {post.commentCount}</span>
                        </div> */}
                      </div>
                      <Link to={`/blog/${post._id}`} className="link-btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Blog;