import starIsland1 from "../assets/services/resedential/star0island/Rectangle-starisland-pics-1.png";
import starIsland2 from "../assets/services/resedential/star0island/Rectangle-starisland-pics-2.png";
import starIsland3 from "../assets/services/resedential/star0island/Rectangle-starisland-pics.png";
import starIsland4 from "../assets/services/resedential/star0island/starIsland4.png";
import starIsland5 from "../assets/services/resedential/star0island/starIsland5.png";
import starIsland6 from "../assets/services/resedential/star0island/starIsland6.png";
import starIsland7 from "../assets/services/resedential/star0island/starIsland7.png";
import starIsland8 from "../assets/services/resedential/star0island/starIsland8.png";
import starIsland9 from "../assets/services/resedential/star0island/starIsland9.png";
import starIsland10 from "../assets/services/resedential/star0island/starIsland10.png";
import starIsland11 from "../assets/services/resedential/star0island/starIsland11.png";
import starIsland12 from "../assets/services/resedential/star0island/starIsland12.png";
import starIsland13 from "../assets/services/resedential/star0island/starIsland13.png";
import starIsland14 from "../assets/services/resedential/star0island/starIsland14.png";
import starIsland15 from "../assets/services/resedential/star0island/starIsland15.png";
import starIsland16 from "../assets/services/resedential/star0island/starIsland16.png";

export const serviceImg = [
  {
    image: starIsland1,
  },
  {
    image: starIsland2,
  },
  {
    image: starIsland3,
  },
  {
    image: starIsland4,
  },
  {
    image: starIsland5,
  },
  {
    image: starIsland6,
  },
  // {
  //   image: starIsland7,
  // },
  // {
  //   image: starIsland8,
  // },
  // {
  //   image: starIsland9,
  // },
  // {
  //   image: starIsland10,
  // },
  // {
  //   image: starIsland11,
  // },
  // {
  //   image: starIsland12,
  // },
  // {
  //   image: starIsland13,
  // },
  // {
  //   image: starIsland14,
  // },
  // {
  //   image: starIsland15,
  // },
  // {
  //   image: starIsland16,
  // },
];
