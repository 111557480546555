import React, { useState } from "react";
import "./Page5.css";
import Switch1 from "../assets/Switch1.png";
import Switch2 from "../assets/Switch2.png";
import Switch3 from "../assets/Switch3.png";
import Switch4 from "../assets/Switch4.png";
import Switch5 from "../assets/Switch5.png";
import Switch6 from "../assets/Switch6.png";
import Switch7 from "../assets/Switch7.png";
import Switch8 from "../assets/Switch8.png";
import Switch9 from "../assets/Switch9.png";
import projectImage1 from "../assets/project_and_development/projectImage1.png";
import projectImage2 from "../assets/project_and_development/projectImage2.png";
import projectImage3 from "../assets/project_and_development/image 126project-and-development.png";

import projectImage4 from "../assets/project_and_development/projectImage4.png";
import projectImage5 from "../assets/project_and_development/projectImage5.png";
import projectImage6 from "../assets/project_and_development/projectImage6.png";
import projectImage7 from "../assets/project_and_development/projectImage7.png";
import projectImage8 from "../assets/project_and_development/projectImage8.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

const Page5 = () => {
  gsap.registerPlugin(ScrollTrigger);
  let aboutRow1Ref = useRef();
  let devImageContainer = useRef();
  let ourAimContainer = useRef();

  let developmentInteraction = gsap.timeline({ repeat: -1 });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    // projectImage1,
    // projectImage2,
    // projectImage3,
    projectImage4,
    projectImage5,
    // projectImage6,
    projectImage7,
    projectImage8,
  ];

  // Additional images for each main image
  const additionalImages = [[projectImage8], [projectImage4], [projectImage7]];

  const switchImages = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Use currentImageIndex to switch the images
  useEffect(() => {
    const imagesContainer = devImageContainer.current;
    imagesContainer.querySelector(".img1").src = images[currentImageIndex];
    imagesContainer.querySelector(".img2").src =
      images[(currentImageIndex + 1) % images.length];
    imagesContainer.querySelector(".img3").src =
      images[(currentImageIndex + 2) % images.length];
  }, [currentImageIndex]);

  // Additional images for each main image
  useEffect(() => {
    const imagesContainer = devImageContainer.current;
    const currentMainImage = imagesContainer.querySelector(
      `.img${currentImageIndex + 1}`
    );
    const additionalImagesArray = additionalImages[currentImageIndex];
    if (currentMainImage) {
      for (let i = 0; i < additionalImagesArray.length; i++) {
        currentMainImage.style.backgroundImage = `url(${additionalImagesArray[i]})`;
      }
    }
  }, [currentImageIndex, additionalImages]);

  useEffect(() => {
    const intervalRef = setInterval(switchImages, 5000);
    return () => clearInterval(intervalRef);
  }, []);

  useEffect(() => {
    let interaction = gsap.context(() => {
      gsap.to(".our-aim", {
        scrollTrigger: {
          trigger: ".our-aim",
          toggleActions: "restart pause resume none",
        },
        transform: "translateX(0%)",
        duration: 1,
        ease: "ease-in",
      });
    }, ourAimContainer);
    return () => interaction.revert();
  }, []);
  useEffect(() => {
    let ctx = gsap.context(() => {
      // var tl = gsap.timeline();
      gsap.to(
        ".col-1",
        {
          scrollTrigger: {
            trigger: ".col-1",
            toggleActions: "restart pause resume none",
          },
          transform: "translateX(0vw)",
          duration: 1,
          ease: "ease",
        },
        1
      );
      gsap.to(
        ".col-2",
        {
          scrollTrigger: {
            trigger: ".col-2",
            toggleActions: "restart pause resume none",
          },
          transform: "translateX(0vw)",
          duration: 1,
          ease: "ease",
        },
        1
      );
    }, aboutRow1Ref);
    return () => ctx.revert();
  }, []);

  useEffect(() => {
    let devanime = gsap.context(() => {
      developmentInteraction
        .to(
          ".img1",
          {
            transform: "translateY(-30%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
          },
          1
        )
        .to(
          ".img3",
          {
            transform: "translateY(-30%)",
            ease: "linear",
            duration: 0.7,
            delay: 5,
          },
          1
        )
        .to(
          ".img1",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".img2",
          {
            transform: "translateY(-30%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".img3",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 10,
          },
          2
        )
        .to(
          ".img2",
          {
            transform: "translateY(0%)",
            ease: "linear",
            duration: 0.7,
            delay: 15,
          },
          3
        );
    }, devImageContainer);
    return () => devanime.revert();
  }, []);

  return (
    <div className="container Page5Main">
      <div ref={devImageContainer} className="img-container">
        <img className="img1" src={projectImage1} alt="estate-pics"></img>
        <img className="img2" src={projectImage2} alt="estate-pics"></img>
        <img className="img3" src={projectImage3} alt="estate-pics"></img>
      </div>
    </div>
  );
};

export default Page5;
