import Accordion from "../components/accordion";
import freqQues from "../content/FAQ";
const Faq = () => {
  return (
    <section className="faq-main-sec">
      <div className="faq-hero">
        <h2>FAQS</h2>
        <h3>Frequently Asked Questions</h3>
        <p>
          Here are the answers to our most asked questions we believe would help
          you know us more.
        </p>
      </div>
      <div className="container">
        {freqQues.map((item, index) => {
          return <Accordion item={item} key={index} />;
        })}
      </div>
    </section>
  );
};
export default Faq;
