import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./pages/homepage";
import About from "./pages/aboutpage";
import Blog from "./pages/blogPage/blogpages";
import BlogDetail from "./pages/blogPage/blogDetail";
// import Navbar from "./components/navbar";
// import Header from "./components/Header";
import Header from "./components/Header";
// import Footer from "./components/footer";
import Faq from "./pages/faq";
import Services from "./pages/services";
import { useState } from "react";
// import Mobilenav from "./components/mobileNav";
import Footer from "./footer/Footer";
import Projectpage from "./pages/projectpage";
import FormPages from "./pages/form";
import Properties from "./pages/properties";
import LuxuryProperties from "./pages/luxury-properties";
import PendingSuite from "./pages/pending-suite";
import Houses from "./pages/Houses";
import Lands from "./pages/Lands";

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [heading, setHeading] = useState("Residential Properties");
  const control = { variable: heading, control: setHeading };
  // console.log(heading);

  const setHeader = (val) => {
    setHeading(val);
  };

  return (
    <>
      <ScrollToTop />
      {/* <Navbar props={control} /> */}
      <Header setHeader={setHeader} />
      {/* <Mobilenav props={control} /> */}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/project/*" element={<Projectpage />} />
        <Route path="/project/lands" element={<Lands />} />
        <Route path="/project/houses" element={<Houses />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/luxury-properties" element={<LuxuryProperties />} />
        <Route path="/pending-suite" element={<PendingSuite />} />

        <Route path="/faq" element={<Faq />} />
        <Route path="/form" element={<FormPages />} />
        <Route path="/services/*" element={<Services props={control} />} />
        <Route path="*" />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
