import React from "react";
import "./Page2.css";
import Page2New from "../assets/Page2New.svg";

const Page2 = () => {
  return (
    <div className="container Page2Main">
      <div className="Page2Parent">
        <h3>Welcome to Our World- Star Island</h3>

        <div className="Page2ParentDiv"></div>
      </div>
 
      <div className="Page2Child">
        <img src={Page2New} alt="" />
        <p>  
          Star Island will be a leading example of cosmopolitan living, boasting
          of a marina, landscaped lake, green spaces, and with an excellent
          array of shops, restaurants and cafés. With the creation of unique
          residential clusters along the island perimeter, each with its own
          identity and secure access to the lake, residents will enjoy a
          lifestyle of serenity but still be minutes from leisure and island
          amenities – the best of both worlds for all to enjoy.
        </p>
      </div>
    </div>
  );
};

export default Page2;
