import image1 from "../assets/herobackground/image1.png";
import image2 from "../assets/herobackground/image2.png";
import image3 from "../assets/herobackground/image3.png";
import image4 from "../assets/herobackground/image4.png";

const heroBackground = [
  { image: image1 },
  { image: image2 },
  { image: image3 },
  { image: image1 },
];

export default heroBackground;
