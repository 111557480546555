import React from "react";
import Page12Img from "../assets/Page12.svg"
import "./Page12.css"

const Page12 = () => {
  return (
    <div className="container Page12Main">
      <div className="Page12Parent"> 
        <h3>Team Mission</h3>

        <div className="Page12ParentDiv"></div>
      </div>

      <div className="Page12Child">  
        <p>
          Star Island will be a leading example of cosmopolitan living, boasting
          of a marina, landscaped lake, green spaces, and with an excellent
          array of shops, restaurants and cafés. With the creation of unique
          residential clusters along the island perimeter, each with its own
          identity and secure access to the lake, residents will enjoy a
          lifestyle of serenity but still be minutes from leisure and island
          amenities – the best of both worlds for all to enjoy.
        </p>
        <img src={Page12Img} alt="" />
      </div>
    </div>
  );
};

export default Page12;
