import { FiChevronRight } from "react-icons/fi";
import { Routes, Route, Link } from "react-router-dom";
import Resedential from "../components/resedential";
import Commercial from "../components/commercial";
import PropInvesConsult from "../components/propertyInvestmentConsultancy";
import PropertyManagment from "../components/propertyManagment";
import RealEstateLegal from "../components/RealEstateLegal";
import { useLocation } from "react-router-dom";
import serImag from "../assets/services/1-service-hero.png";
import serimg2 from "../assets/services/serimg2.png";

const style = {
  backgroundColor: "#b77f30",
  color: "white",
};

const Services = ({ props }) => {
  const { variable, control } = props;
  const location = useLocation();

  
  return (
    <section className="services">
      <div className="hero">
        <h2>
          If You Are Looking To Buy Your First
          <br /> Investment Property Or Build Your
          <br />
          Investment Portfolio, We Can Help!
        </h2>
      </div>

      <div className="content">
        <h2>
          <hr />
          {variable}
        </h2>
        <div className="main">
          <ul className="d-none d-lg-block">
            <li>
              {location.pathname.includes("/services/residential") ? (
                <Link
                  style={style}
                  onClick={() => control("Residential Properties")}
                  className="link"
                  to="residential"
                >
                  Residential Properties <FiChevronRight className="icon" />
                </Link>
              ) : (
                <Link
                  onClick={() => control("Residential Properties")}
                  className="link"
                  to="residential"
                >
                  Residential Properties <FiChevronRight className="icon" />
                </Link>
              )}
            </li>
            <li>
              {location.pathname.includes("/services/commercial") ? (
                <Link
                  style={style}
                  onClick={() => control("Commercial Properties")}
                  className="link"
                  to="commercial"
                >
                  Commercial Properties
                  <FiChevronRight className="icon" />
                </Link>
              ) : (
                <Link
                  onClick={() => control("Commercial Properties")}
                  className="link"
                  to="commercial"
                >
                  Commercial Properties
                  <FiChevronRight className="icon" />
                </Link>
              )}
            </li>
            <li>
              {location.pathname.includes(
                "/services/property-investment-consultancy"
              ) ? (
                <Link
                  style={style}
                  onClick={() => control("Property Investment Consultancy")}
                  className="link"
                  to="property-investment-consultancy"
                >
                  Property Investment Consultancy
                  <FiChevronRight className="icon" />
                </Link>
              ) : (
                <Link
                  onClick={() => control("Property Investment Consultancy")}
                  className="link"
                  to="property-investment-consultancy"
                >
                  Property Investment Consultancy
                  <FiChevronRight className="icon" />
                </Link>
              )}
            </li>
            <li>
              {location.pathname.includes("/services/property-managment") ? (
                <Link
                  style={style}
                  onClick={() => control("Property Managment")}
                  className="link"
                  to="property-managment"
                >
                  Property Managment
                  <FiChevronRight className="icon" />
                </Link>
              ) : (
                <Link
                  onClick={() => control("Property Managment")}
                  className="link"
                  to="property-managment"
                >
                  Property Managment
                  <FiChevronRight className="icon" />
                </Link>
              )}
            </li>
            <li>
              {location.pathname.includes("/services/real-estate-legal") ? (
                <Link
                  style={style}
                  onClick={() => control("Real Estate Legal Assistant")}
                  className="link"
                  to="real-estate-legal"
                >
                  Real Estate Legal assistant
                  <FiChevronRight className="icon" />
                </Link>
              ) : (
                <Link
                  onClick={() => control("Real Estate Legal Assistant")}
                  className="link"
                  to="real-estate-legal"
                >
                  Real Estate Legal assistant
                  <FiChevronRight className="icon" />
                </Link>
              )}
            </li>
          </ul>
          <div className="main-content">
            <Routes>
              <Route path="/residential" element={<Resedential />}></Route>
              <Route path="/commercial" element={<Commercial />}></Route>
              <Route
                path="/property-investment-consultancy"
                element={<PropInvesConsult />}
              ></Route>
              <Route
                path="/property-managment"
                element={<PropertyManagment />}
              ></Route>
              <Route
                path="/real-estate-legal"
                element={<RealEstateLegal />}
              ></Route>
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
