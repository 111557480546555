import React from "react";
import "./PropertiesProps.css";

const PropertiesProps = ({ data }) => {
  return (
    <div className="container Properties1Main">
      {data &&
        data.map((d) => (
          <div key={d.id}>
            <div className="Properties1Parent">
              <div className="Properties1Bro">
                <img src={d.image1} alt="" />
                <img src={d.image2} alt="" />
                <img src={d.image3} alt="" />
                <img src={d.image4} alt="" />
              </div>

              <div className="Properties1Child">
                <img src={d.mainImage} alt="" />
              </div>
            </div>

            <div className="Properties1Sister">
              <div className="Properties1SubSister">
                <h6> {d.propertyType} </h6>
                <p>
                  {" "}
                  {d.value} /<span>{d.value2}</span>{" "}
                </p>
              </div>
              <div className="PropertiesCousin">
                <p>
                  {" "}
                  <img src={d.bell} alt="" /> {d.squareMeter}{" "}
                </p>
                <p>
                  <img src={d.bell} alt="" /> {d.space}{" "}
                </p>
                <p>
                  <img src={d.bell} alt="" /> {d.sitting}{" "}
                </p>
                <p>
                  <img src={d.bell} alt="" /> {d.sittingNo}{" "}
                </p>
              </div>

              <div className="PropertiesNiece">
                <p> {d.body} </p>
                <p>
                  <strong>Location:</strong> {d.location}
                </p>
                {/* <p> {d.Areaofland} </p> */}

                {d.Areaofland && (
                  <p>
                    <strong>{d.Areaofland.substring(0, 12)}</strong>
                    {d.Areaofland.substring(12)}
                  </p>
                )}

                {d.Title && (
                  <p>
                    {" "}
                    <strong> {d.Title.substring(0, 5)} </strong>
                    {d.Title.substring(5)}
                  </p>
                )}

                {/* <p> {d.Title} </p> */}
              </div>

              <div className="PropertiesButton">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {" "}
                  {d.button}{" "}
                </button>
              </div>

              <div className="PropertiesFacility">
                <h2> {d.facility} </h2>

                <div className="PropertiesFacilityMain">
                  <ul className="facility-list">
                    <li> {d.facility1} </li>
                    <li> {d.facility2} </li>
                    <li> {d.facility3} </li>
                    <li> {d.facility4} </li>
                    <li> {d.facility5} </li>
                    <li> {d.facility6} </li>
                    <li> {d.facility7} </li>
                    <li> {d.facility8} </li>
                  </ul>
                </div>
              </div>

              <div className="PropertiesButton">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {" "}
                  {d.button}{" "}
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default PropertiesProps;
