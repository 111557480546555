import { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

const Form = ({ width }) => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};
    if (!formData.user_name) {
      errors.user_name = "Name is required";
    }
    if (!formData.user_email) {
      errors.user_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      errors.user_email = "Email is invalid";
    }
    if (!formData.user_phone) {
      errors.user_phone = "Phone number is required";
    }
    if (!formData.message) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const errors = validate();
    if (Object.keys(errors).length === 0) {
      const templateParams = {
        user_email: formData.user_email,
        user_phone: formData.user_phone,
        message: formData.message,
      };

      emailjs
        .sendForm(
          "service_3ext5oh",
          "template_nxnyg6e",
          form.current,
          "KrXbQ1_YXk69yg5nN",
          templateParams
        )
        .then(
          () => {
            toast.success("Email sent successfully!");
            setFormData({ user_name: "", user_email: "", user_phone: "", message: "" });
          },
          (error) => {
            toast.error("Failed to send email. Please try again later.");
            console.log("FAILED...", error.text);
          }
        );
    } else {
      setErrors(errors);
    }
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input
          type="text"
          name="user_name"
          value={formData.user_name}
          onChange={handleChange}
        />
        {errors.user_name && <p className="error">{errors.user_name}</p>}

        <label>Email</label>
        <input
          type="email"
          name="user_email"
          value={formData.user_email}
          onChange={handleChange}
        />
        {errors.user_email && <p className="error">{errors.user_email}</p>}

        <label>Phone Number</label>
        <input
          type="text"
          name="user_phone"
          value={formData.user_phone}
          onChange={handleChange}
        />
        {errors.user_phone && <p className="error">{errors.user_phone}</p>}

        <label>Message</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        {errors.message && <p className="error">{errors.message}</p>}

        <button type="submit">Book an appointment</button>
      </form>
      <ToastContainer />
    </>
  );
};
export default Form;