import React from "react";
import Page10a from "../assets/Page10a.png";
import Page10b from "../assets/Page10b.png";
import Page10c from "../assets/Page10c.png";
import Page10d from "../assets/Page10d.png";
import Page10e from "../assets/Page10e.png";
import Page10f from "../assets/Page10f.png";
import Page10g from "../assets/Page10g.png";
import Page10h from "../assets/Page10h.png";
import Page10i from "../assets/Page10i.png";
import Page10j from "../assets/Page10j.png";
import Page10k from "../assets/Page10k.png";
import Page10l from "../assets/Page10l.png";
import Page10m from "../assets/Page10m.png";
import Page10n from "../assets/Page10n.png";

import "./Page10.css";
import CarouselProps from "./CarouselProps";

const Page13 = () => {
  const data = [
    {
      image: Page10a,
      header: "Block of 3 Bedroom Flats on 8 Floors",
      texta: "Adequately segregated public and private areas    ",
      textb: "Kitchen, Pantry, Food store and linen store",
      textc: " And many more......",
    },
    {
      image: Page10b,
      header: "Block of 5 Bedroom Flats on 8 Floors",
      texta: "Adequately segregated public and private areas    ",
      textb: "Kitchen, Pantry, Food store and linen store",
      textc: " And many more......",
    },
    {
      image: Page10c,
      header: "Block of 4 Bedroom Flats on 10 Floors",
      texta: "Adequately segregated public and private areas    ",
      textb: "Kitchen, Pantry, Food store and linen store",
      textc: " And many more......",
    },
    {
      image: Page10d,
      header: "Block of 4 Bedroom Flats on 10 Floors",
      texta: "Adequately segregated public and private areas    ",
      textb: "Kitchen, Pantry, Food store and linen store",
      textc: " And many more......",
    },
    {
      image: Page10e,
      header: "Block of 5 Bedroom Duplex",
      texta: "Adequately segregated public and private areas    ",
      textb: "Kitchen, Pantry, Food store and linen store",
      textc: " And many more......",
    },
    {
      image: Page10f,
      header: "Hospital Facility",
      texta: "Equipped with a variety of medical",
      textb: "Team of highly trained medical professionals ",
      textc: " And many more......",
    },
    {
      image: Page10g,
      header: "Office Block",
      texta: "Reliable high-speed internet connectivity     ",
      textb: "Adequate kitchen areas or break rooms",
      textc: " And many more......",
    },
    {
      image: Page10h,
      header: "Five Star Hotel Facility",
      texta: "Adequately segregated public and private areas    ",
      textb: "Dedicated service ducts, refuse chutes, escape stairs.",
      textc: " And many more......",
    },
    {
      image: Page10i,
      header: "Shopping Mall and Shops",
      texta: "Enclosed and climate-controlled    ",
      textb: "Ample parking facilities",
      textc: " And many more......",
    },
    {
      image: Page10j,
      header: "Market and Commercial Facility",
      texta: "POS systems, which include cash registers    ",
      textb: "Efficient inventory management",
      textc: " And many more......",
    },
    {
      image: Page10k,
      header: "Clubs with Gym and Wellness Center",
      texta: "Well-equipped fitness center    ",
      textb: "Provide the option to hire certified personal trainers",
      textc: " And many more......",
    },
    {
      image: Page10l,
      header: "Marina & Yacht Club and Jetty Facilities",
      texta: "Docking spaces or moorings for boats and yachts    ",
      textb: "Team of highly trained medical professionals",
      textc: " And many more......",
    },
    {
      image: Page10m,
      header: "18 Holes Golf Course with Club House",
      texta: "A central hub for golfers and visitors    ",
      textb: "Provide golf carts for players",
      textc: " And many more......",
    },
    {
      image: Page10n,
      header: "Sporting Facilities",
      texta: "Well-maintained playing   ",
      textb: "Adequate lighting ",
      textc: " And many more......",
    },
  ];
  const headLine = "Building Types ";
  return (
    <div>
      <CarouselProps data={data} headLine={headLine} />
    </div>
  );
};

export default Page13;
