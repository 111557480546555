import { FiChevronUp } from "react-icons/fi";
import { useState } from "react";
const Accordion = ({ item }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [classs, setClasss] = useState("closed");
  const toggleAccordion = () => {
    if (isOpen) {
      setIsOpen(false);
      setClasss("open");
    } else {
      setIsOpen(true);
      setClasss("closed");
    } 
  };  
  return (
    <div className={classs}>  
      <div className="faq">
        <p className="ques">
          {item.ques}
          <FiChevronUp onClick={toggleAccordion} className="icon" />
        </p>
        <p className="ans">{`Ans: ${item.ans}`}</p>
      </div>
    </div>
  );
};

export default Accordion;
