import React, { useState } from "react";
import maxlogo from "../assets/image 122-small-logo.svg";
import LogoNew from "../assets/LogoNew.png";
import Hambuga from "../assets/Hambuga.svg";
import HambugaX from "../assets/HambugaX.svg";
import { Link } from "react-router-dom";
import Form from "./form";
import { useLocation } from "react-router-dom";

const Header = ({ setHeader, control }) => {
  const url = window.location.href;
  const location = useLocation();
  //  const { control } = props;
  console.log(setHeader);

  const headerHandler = (val) => {
    setHeader(val);
  };

  const [isToggled, setIsToggled] = useState(false);

  const toggleNavbar = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div>
      <nav class="navbar fixed-top navbar-expand-lg">
        <div class="container">
          <Link class="navbar-brand" to="/">
            <img src={LogoNew} height={80} alt="maxi-logo"></img>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            {/* <span class="navbar-toggler-icon"></span> */}

            <img src={isToggled ? HambugaX : Hambuga} alt="Maximum Luxury" />
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    Our Projects
                  </button>
                  <ul className="dropdown-menu dropdown-menu-lg-end">
                    <li>
                      <Link className="dropdown-item" to="project">
                        Star Island
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/project/houses">
                        Houses
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/project/lands">
                        Lands
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    Services
                  </button>
                  <ul class="dropdown-menu dropdown-menu-lg-end">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/services/residential"
                        onClick={() => headerHandler("Residential Properties")}
                        // onClick={() => control("Residential Properties")}
                      >
                        Residential Properties
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        onClick={() => headerHandler("Commercial Properties")}
                        // onClick={() => control("Commercial Properties")}
                        className="dropdown-item"
                        to="/services/commercial"
                      >
                        Commercial Properties
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        onClick={() =>
                          headerHandler("Property Investment Consultancy")
                        }
                        className="dropdown-item"
                        to="/services/property-investment-consultancy"
                      >
                        Property Investment Conultancy
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        onClick={() => headerHandler("Property Managment")}
                        className="dropdown-item"
                        to="/services/property-managment"
                      >
                        Property Management
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        onClick={() =>
                          headerHandler("Real Estate Legal Assistant")
                        }
                        className="dropdown-item"
                        to="/services/real-estate-legal"
                      >
                        Real Estate Legal Assist..
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <Link
                  className={
                    location.pathname === "/about"
                      ? "nav-link active headerActiveColor"
                      : "nav-link"
                  }
                  aria-disabled="true"
                  to="/about"
                >
                  About Us
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={
                    location.pathname === "/blog"
                      ? "nav-link active headerActiveColor"
                      : "nav-link"
                  }
                  aria-disabled="true"
                  to="/blog"
                >
                  Blog
                </Link>
              </li>
              <li class="nav-item contact-button">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Book An Appointment With Us</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Form width={"100%"} margin="0 auto" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
