const freqQues = [
  {
    ques: "Where Is Marina Luxury Estate Located?",
    ans: "Ans: MARINA LUXURY ESTATE is situate at EKO AKETE Lekki-Epe Expressway, Ibeju Lekki LGA, Lagos State",
  },
  {
    ques: "Why Should I Buy Marina Luxury Estate?",
    ans: "Ans: MARINA LUXURY ESTATE enjoys proximity to major commercial investment landmarks like the ShopRite Sangotedo Resort, New Int'l Airport, Deep Sea Port, Dangote Renery, Lekki Free Trade Zone etc guaranteeing hyper Return on Investment",
  },
  {
    ques: "Who Are The Owners Of Marina Luxury Estate Eko Akete?",
    ans: "Ans: UNLIMITED HOMES LTD, multiple award-winning real estate company & winner of the “BEST REAL ESTATE SOLICITOR 2020",
  },
  {
    ques: "What Type Of Title Does Marina Luxury Estate Have On The Land?",
    ans: "Ans: Gazette",
  },
  {
    ques: "Are There Any Encumbrances On The Land?",
    ans: "Ans: The land is free from every known government acquisition or interest and adverse claims.",
  },
  {
    ques: "What Plot Size(s) Is Available?",
    ans: "Ans: 300sqm & 600sqm",
  },
  {
    ques: "What Is The Payment Plan For The Plot Sizes?",
    ans: "Ans: (a) N6000,000 (300sqm)- N2000,000 initial deposit, N500,000 monthly for 12 months (b) N12000,000 (600sqm)- N4000,000 initial deposit, N5000,000 monthly for 12 months (d) Corner piece and commercial plots attract additional 20% of land cost (e) Non-payment of the monthly installments as at when due and non-compliance with the payment structure shall be treated as a fundamental breach of the contract which may result in termination or revocation of the contract/OR attract default charge of 5% of the month payment or 5% of the total balance upon demand, OR 5% of the outstanding payment for every month of default after payment expiration.",
  },
  {
    ques: "Is The Road To The Estate Motorable?",
    ans: "Ans: Yes the road to the estate is motorable.",
  },
  {
    ques: "What Other Payments Do I Make With/After The Payment For The Land?",
    ans: "Ans: (a) Deed of Assignment: N150,000 (300sqm); N300,000 (600sqm) (b) Registered Survey Plan Fee: N100,000/plot (300sqm); N200,000/plot (600sqm) (c) Plot Demarcation: N100,000/plot for 300sqm & N250,000 for 600sqm (d) Development Fee: N2000, 000 per square metre (Development fee covers the following: (1) Drainage construction (2) Transformers and Electrication (3) Tarred or interlocked roads (4) Building of special amenities (5) Landscaping and beautication of the estate. etc. NB: Development fees unpaid within 12 months of allocation will attract 2.5% monthly appreciation value.",
  },
  {
    ques: "When Do I Get My Allocation After Payment?",
    ans: "Ans: Allocation Document would be issued within three (3) months after payment and physical allocation to be done during the annual dry season in order of subscription provided that at least 25% of development fees has been paid.",
  },
  {
    ques: "When Do I Make The Other Payments?",
    ans: "Ans: (i) Survey Plan, Deed of Assignment and Plot Demarcation should be paid before Physical Allocation. (ii) Development fees should be paid either on installment (over a 12 months' period) or outright basis before or after physical allocation",
  },
  {
    ques: "What Do I Get After The Initial Deposit?",
    ans: "Ans: A letter of acknowledgement of subscription and receipt of payment.",
  },
  {
    ques: "What Do I Get After Completing Payment For The Land?",
    ans: "(a) Completion Payment Receipt, Notication Letter, Contract of Sales & Plot Allocation Document (b) Deed of Assignment & Survey Plan within three (3) months of payment for Q8(a) & (b), provided that allocation has been done.",
  },
  {
    ques: "When Do I Make The Other Payments?",
    ans: "Ans: (i) Survey Plan, Deed of Assignment and Plot Demarcation should be paid before Physical Allocation. (ii) Development fees should be paid either on installment (over a 12 months' period) or outright basis before or after physical allocation",
  },
  {
    ques: "Can I Start Constructing Or Building On The Land Now?",
    ans: "Ans: You can start building on the land after Physical Allocation. Fencing & Gatehouse to be constructed within the first year of introducing the estate and other infrastructure will commence from the 2nd year with regard to the general level of development in the area.",
  },
  {
    ques: "Is There Any Time Limit To Commence Work On My Land After Allocation?",
    ans: "Ans: No.",
  },
  {
    ques: "Is There Any Restriction Regarding The Type Of Building I Can Construct In The Estate?",
    ans: "Ans: Yes. The estate layout is in sections and you are limited to build houses on each section based on designated use or plan for that section (Commercial or Residential) i.e Bungalow, Block of Flats, detach houses (duplex). Note “Face-me-I –Face – you” (Tenement Building) and high-rise houses will not be permitted. All building design must conform to the required set back of building control of the estate and such design would be approved by the company and with LASG afterwards.",
  },
  {
    ques: "Can I Re-Sell My Plot/Property?",
    ans: "Ans:Yes a subscriber who has paid up on their land can re-sell their plot(s). In that event, UNLIMITED HOMES LTD would require you (the seller) to furnish the company with details of the new buyer. UNLIMITED HOMES does not sell on behalf of subscribers. B. 10% of the land consideration paid by you will be payable by the new buyer directly or through you to the Company for Transfer of Title Documentation.",
  },
  {
    ques: "Can I Pay Cash To Your Agent?",
    ans: "Ans: We strongly advise that cash payments should only be made to UNLIMITED HOMES LTD. at its designated Banks. Otherwise, cheque(s)/bank drafts should be issued in favour of UNLIMITED HOMES LTD. We shall not accept any responsibility for any liability that may arise as a result of a deviation from the above instruction.",
  },
  {
    ques: "What Happens If I Cannot Continue With The Payment Or After Payment But Before Allocation? Can I Get A Refund?",
    ans: "Ans: Yes you can get back but less 40% of your money will be deducted.",
  },
];

export default freqQues;
