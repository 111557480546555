import React from "react";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import Page9 from "./Page9";
import Page10 from "./Page10";
import Page11 from "./Page11";
import Page12 from "./Page12";
import Page13 from "./Page13";
import Page14 from "./Page14";

const Index = () => {
  return (
    <div>
      <Page1 />
      <Page2 />
      <Page3 />
      <Page5 />
      <Page14 />
      <Page9 />
      <Page7 />
      <Page8 />
      <Page13 />
      {/* <Page10 /> */}
      {/* <Page4 />
      <Page6 /> */}
      <Page11 />
      <Page12 />
    </div>
  );
};

export default Index;
