import React from "react";
import Properties2 from "../properties/Properties2";

const LuxuryProperties = () => {
  return (
    <div>
      <Properties2 />
    </div>
  );
};

export default LuxuryProperties;
