import icon1 from "../assets/why-icons/Vector-why-icons-1.png";
import icon2 from "../assets/why-icons/Vector-why-icons-2.png";
import icon3 from "../assets/why-icons/Vector-why-icons.png";
import icon4 from "../assets/why-icons/streamline_money-cash-bag-dollar-bag-payment-cash-money-finance-why-icons.png";
import icon5 from "../assets/why-icons/streamline_money-cash-coins-stack-accounting-billing-payment-stack-cash-coins-currency-money-finance-why-icons.png";

const reasons = [
  {
    icon: icon3,
    heading: "1. Diversification",
    message:
      "Step into a world of contemporary design where luxury emanates from different angles. This project features a stylish and sophisticated design with quality fittings and beautiful finishing. Aidaville Courts will offer luxury benefits that will enhance your lifestyle in the hustle and bustle of Lagos and� bring you tranquility, convenience and peace of mind.",
  },
  {
    icon: icon1,
    heading: "2. Massive Potential For Appreciation",
    message:
      "MAXIMUM LUXURY APARTMENTS can appreciate in value over time, providing a potential source of long-term wealth The appreciation you gain in your MAXIMUM LUXURY APARTMENTS portfolio provides a great well for you to dip into if you need emergency funds for your business or if you need to show additional collateral to obtain a bank loan for your business.",
  },
  {
    icon: icon4,
    heading: "3. Cash flow",
    message:
      "Having extra cash coming in from another stream of revenue never hurts. Rental income from properties can provide a consistent source of cash flow for business owners, in addition to the income from your company. You never know when you'll need extra funds for a business acquisition or some sort of pivot that may happen within your company. Having an additional income stream from your rental property with MAXIMUM LUXURY APARTMENTS can only help you in your business endeavors.",
  },
  {
    icon: icon2,
    heading: "4. Tax benefits",
    message:
      "MAXIMUM LUXURY APARTMENTS investments can offer various tax benefits, such as deductions for mortgage interest and depreciation. Additionally, You can also take depreciation deductions on MAXIMUM LUXURY APARTMENTS, which helps to lower your taxable income. As your business grows and your income grows, having helpful, strong tax deductions from your MAXIMUM LUXURY APARTMENTS investments consistently come in handy for you to keep more of your hard-earned money.",
  },
  {
    icon: icon5,
    heading: "5. Inflation hedge",
    message:
      "MAXIMUM LUXURY APARTMENTS investments can act as an inflation hedge, meaning that they can protect your wealth against inflation. As the value of money decreases over time due to inflation, the value of MAXIMUM LUXURY APARTMENTS tends to increase, helping to preserve the value of the investment. As business owners, we are always looking for ways to maximize our money. Inflation literally evaporates the value of our hard-earned naira and having a healthy MAXIMUM LUXURY APARTMENTS portfolio simply helps you to hedge your bets.",
  },
];

export default reasons;
