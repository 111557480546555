import React from "react";
import PropertiesProps from "./PropertiesProps";
import PropertiesMainImage from "../../src/assets/PropertiesMainImageTwo.png";
import PropertiesMainImage1 from "../../src/assets/PropertiesMainImageTwoa.png";

import bell from "../assets/bell.png";

const Properties2 = () => {
  return (
    <div>
      <PropertiesProps data={data} />
    </div>
  );
};

export default Properties2;

const data = [
  {
    id: 1,
    mainImage: PropertiesMainImage,
    image1: PropertiesMainImage1,
    image2: PropertiesMainImage1,
    image3: PropertiesMainImage1,
    image4: PropertiesMainImage1,
    propertyType: "5-Bedroom Flats on 2 Floors",
    value: "$1.2 million",
    value2: "unit",
    squareMeter: "above 3,100 sqm",
    space: "Spacious Room",
    sitting: "Sitting Space",
    sittingNo: "4.8",
    bell: bell,
    body: "Fully fenced 8 units of detached apartments. Each unit has a 5-bedroom ensuite, living room, lounge area, relaxation area, mini swimming pool, and lift on 2 floors. Having 2 parking spaces for each apartment.",
    location: "Ikoyi, Lagos state",
    Areaofland: "",
    button: "Book an Inspection",

    facility: "Property  Facilities",
    facility3: "2 parking space/apartment",
    facility4: "swimming pool",
    facility5: "King sized bedroom",
    facility6: "Bath",
    facility7: "Double Sink",
    facility8: "Shower",
  },
];
