import React from "react";
import Index from "../Project-page/Index";

const Projectpage = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default Projectpage;
