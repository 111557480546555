import React from "react";
import "./Page9.css";
import Page9a from "../assets/Page9a.svg";
import Page9b from "../assets/Page9b.svg";
import Page9c from "../assets/Page9c.svg";

const Page9 = () => {
  return (
    <div className="container Page9Main">
      <div className="Page9Parent">
        <h3>Location</h3>
        <div className="Page9ParentDiv"></div>
      </div>

      <div className="page9Child">
        <img src={Page9c} alt="" />
        <p>
          Star Island is located beside Lekki Phase 1, between Orange Island &
          Banana Island. Star Island is easily accessible via Lekki-Ikoyi Link
          Bridge and Lekki-Epe Expressway. It is a few minutes from the shopping
          and leisure along Admiralty Way and Freedom Way
        </p>
      </div>

      <div className="Page9Bro">
        <img src={Page9a} alt="" />
        <img src={Page9b} alt="" />
      </div>
    </div>
  );
};

export default Page9;
