import React from "react";
import maxlogo from "../assets/image 121-max-logo.svg";
import Linkedin from "../assets/LinkedIn.svg";
import Instagram from "../assets/Instagram.svg";
import Twitter from "../assets/Twitter.svg";
import Youtube from "../assets/Youtube.svg";
import Facebook from "../assets/Facebook.svg";
import WhatsApp from "../assets/WhatsApp.svg";

function Footer() {
  return (
    <div className=" container-fluid footerMainParent">
      <div className="container footerMain">
        <div className="footerMain">
          <div className="footerParent">
            <a href="/">
              <img src={maxlogo} alt="Maximum_Luxury"></img>
            </a>

            <h2>
              We partake in construction and development processes from start to
              finish by providing real estate development solutions to our
              clients.
            </h2>

            <div className="footerIcons">
              {/* <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="footericons2">
                  <img
                    src={Linkedin}
                    width={40}
                    height={78}
                    alt="Maximum_Luxury"
                  />

            
                </div>
              </a> */}

              <a
                href="https://wa.me/2348143784153"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="footericons2">
                  <img
                    src={WhatsApp}
                    width={40}
                    height={40}
                    alt="Maximum_Luxury"
                  />
                </div>
              </a>

              <a
                href="https://www.facebook.com/Maximumluxuryapartmentsltd?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="footericons2">
                  {" "}
                  <img
                    src={Facebook}
                    width={40}
                    height={78}
                    alt="Maximum_Luxury"
                  />
                  {/* <h6>Facebook</h6> */}
                </div>
              </a>

              {/* <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="footericons2">
                  {" "}
                  <img
                    src={Youtube}
                    width={40}
                    height={78}
                    alt="Maximum_Luxury"
                  />
                  
                </div>
              </a> */}

              <a
                href="https://instagram.com/maximumluxuryapartmentsltd?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="footericons2">
                  {" "}
                  <img
                    src={Instagram}
                    width={40}
                    height={78}
                    alt="Maximum_Luxury"
                  />
                  {/* <h6>Instagram</h6> */}
                </div>
              </a>
            </div>
          </div>

          <div className="footerChildren">
            <div className="footerChild">
              <h3>About Us</h3>
              <a href="/">
                <p>Services</p>
                <p>Our Team</p>
                <p>Terms And Conditions</p>
                <p>Privacy policy</p>
                <p>Help</p>
              </a>
            </div>

            <div className="footerChild">
              <h3>Contact Us</h3>
              <p> +2348143784153</p>
              <p>enquiry@maximumluxury.com.ng</p>
              <p>
                {" "}
                Penthouse, Plot 3 Yesufu Abiodun Road, Beside Fourpoint Sharaton
                Hotel, Oniru Estate, Victoria Island
              </p>

              {/* <a href="">
                <p> Enquiries </p>
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footerCopyright">
        <p> © 2023 Copyright Maximum Luxury Apartment. All rights reserved</p>
      </div>
    </div>
  );
}

export default Footer;
