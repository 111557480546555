import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const client = sanityClient({
  projectId: 'gr1sceb4', // Your project ID
  dataset: 'production', // Your dataset name
  apiVersion: '2023-06-11', // Use a specific date to fix API version, or 'v1' for the latest version
  useCdn: true, // Use CDN for faster responses (set to false if you want fresh data)
  // token: 'skjwrBRC07PoWWXIsONwanhoaf1twGPru6TVKmwrjugaWbm2WBZj70dVnoJCagWw5O7OlThkHcGsVOXXkhlMTGYy8630QFCPM2WmiAjFd0Y67CZIk3lDJ3tArwqNsErbBcnRGP90sa1YjHbxo64G6Hk7vBuZTNXtjnZeLPkzaMo78GebAeN9'
  token: 'sk9mzPd8CzY558q1XWEewF4fZFaAIlQ0kY21lTr5ZCzgReeDGDZ1Y5F5e2uX77YDXurQZR3HxuwcTG2XPsmYK27YqewftMs8jeVdkgZkr1oZfz70hstFZ0DiJI21W2y6aPgI8yjhwOsn14FL93dtWfNSOhfEQITD5F0lHYvlRX3m5Q4TgqWZ'
});

const builder = imageUrlBuilder(client);

export function urlFor(source) {
  return builder.image(source);
}

export default client;
